import clsx from 'clsx';
import React from 'react';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  /** Titulo a desplegar en el tag */
  title: string;
  /** Icono a desplegar en el tag */
  icon?: React.ReactNode;
  /** Tipo de estilo de tag  */
  type: 'warning' | 'error';
};
const classes = {
  base: `md:ml-2 mr-1 inline-flex  items-center font-medium px-2 py-1 rounded-full`,
  baseTitle: `text-xs uppercase`,
  icon: `w-3 h-3 mr-1 flex-shrink-0`,
  getTypeBg(type: 'warning' | 'error') {
    switch (type) {
      case 'error':
        return `bg-red-200`;
      case 'warning':
        return `bg-amber-50`;
    }
  },
  getTypeFont(type: 'warning' | 'error') {
    switch (type) {
      case 'error':
        return `text-red-700`;
      case 'warning':
        return `text-amber-900`;
    }
  },
};
function Tag({ title, icon, type, className }: Props) {
  return (
    <div className={clsx([classes.base, classes.getTypeBg(type), className])}>
      <div className={clsx([classes.icon, classes.getTypeFont(type)])}>
        {icon ? (
          icon
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
            />
          </svg>
        )}
      </div>

      <span className={clsx([classes.baseTitle, classes.getTypeFont(type)])}>{title}</span>
    </div>
  );
}

export default Tag;
