import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/toast';
import Modal from 'components/Modal';
import Toast from 'components/Toast';
import removeSchedule from 'graphql/mutations/removeSchedule';
import getUserSchedules from 'graphql/queries/getUserSchedules';
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  ModalProps,
  RemoveScheduleMutationData,
  RemoveScheduleMutationVars,
  ToastContent,
} from 'library/app-types';
import CloseIcon from 'library/icons/CloseIcon';
import SpinIcon from 'library/icons/SpinIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { removeUserSchedules } from 'redux/slices/schedulesSlice';
import { RootState } from 'redux/store';
import { mixpanel } from 'service/mixpanel';

const modalClasses = {
  panelContainer: `relative w-full my-6 mx-auto max-w-xs `,
  panelContent: `border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white dark:bg-darkGray outline-none focus:outline-none `,
};

export default function UnpinModal(props: ModalProps): React.ReactElement<ModalProps> {
  const { toggle, isOpen } = props;
  const inputRef = useRef<any>();
  const [name, setName] = useState('');
  const [ableToDelete, setAbleToDelete] = useState(false);
  const dispatch = useAppDispatch();
  const [commitMutation, { loading }] = useMutation<
    RemoveScheduleMutationData,
    RemoveScheduleMutationVars
  >(removeSchedule);
  const toast = useToast();
  const currentUserSchedule = useSelector(
    (state: RootState) => state.schedules.currentUserSchedule,
  );

  const displayToastAlert = (content: ToastContent) => {
    toast({
      render: (props) => {
        return <Toast onClose={props.onClose} {...content} />;
      },
    });
  };

  const handleSetAbleToDelete = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const removeUserScheduleMutation = async () => {
    if (currentUserSchedule) {
      await commitMutation({
        variables: {
          id: currentUserSchedule.id,
        },
        refetchQueries: [
          {
            query: getUserSchedules,
          },
        ],
      });
      dispatch(removeUserSchedules(currentUserSchedule.id));

      mixpanel.track('Unpin Schedule', {
        savedScheduleId: currentUserSchedule.id,
      });

      displayToastAlert({
        title: 'Hecho!',
        description: 'Horario eliminado de favoritos',
        type: 'good',
      });
    }
  };

  /**
   * Función para desmarcar el horario en el que te encuentras actualmente.
   */
  const handleUnPinSchedule = () => {
    if (currentUserSchedule) {
      try {
        removeUserScheduleMutation();
      } catch (error) {
        displayToastAlert({
          title: '¡Ocurrió un error!',
          description: 'No se pudo desmarcar el horario, intenta más tarde',
          type: 'bad',
        });
      }
    }
    toggle();
  };

  /** Este efecto esta pendiente si el nombre escrito por el usuairio
   * es igual al nombre del horario a desmarcar, de manera que le permita habilitar el boton
   * que desmarca el horario.
   */
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (name.trim() === currentUserSchedule?.name.trim()) {
        setAbleToDelete(true);
      } else {
        setAbleToDelete(false);
      }
    }

    return () => {
      mounted = false;
    };
  }, [name, currentUserSchedule]);

  /**
   * Establece el focus en el input al inicializarse el modal.
   */
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <Modal onClose={toggle} isOpen={isOpen}>
      {/* Panel */}
      <div className={modalClasses.panelContainer}>
        <div className={modalClasses.panelContent}>
          <section className="px-9 pt-11 pb-4">
            <h1 className="text-xl sm:text-4xl text-gray-900 dark:text-whiteLg-high font-bold">
              Desmarcar horario
            </h1>
          </section>
          <button
            tabIndex={0}
            onClick={toggle}
            className="absolute right-6 top-6 w-5 h-5 rounded-lg text-gray-400"
          >
            <CloseIcon />
          </button>
          <section className="pb-11 px-9 flex flex-col">
            <p className="text-sm pb-4 text-gray-700 dark:text-whiteLg">
              Esta operación no tiene marcha atras. Por favor escribe{' '}
              <span className="font-medium dark:text-gray-50 text-gray-900">
                {currentUserSchedule?.name}
              </span>{' '}
              para desmarcar el horario
            </p>
            <input
              ref={inputRef}
              tabIndex={0}
              className=" block w-full rounded-md border-gray-300 dark:bg-darkGray dark:text-gray-50 dark:border-darkGray-light shadow-sm focus:border-primary focus:ring focus:ring-primary-light text-sm focus:ring-opacity-50"
              type="text"
              autoComplete="false"
              value={name}
              onChange={handleSetAbleToDelete}
              placeholder=""
            />
          </section>
          <hr className="divide divide-x-1 divide-gray-200 w-full"></hr>

          {/* TODO: Desacoplar en componentes */}
          <button
            onClick={handleUnPinSchedule}
            disabled={!ableToDelete || loading}
            className="px-8 py-5 text-sm disabled:opacity-25 font-semibold flex justify-center text-white text-center bg-red-500 dark:bg-red-200 dark:text-gray-900 w-full rounded-b-3xl focus:outline-none focus:ring-1 focus:ring-offset-red-300 hover:opacity-95 active:bg-red-700 dark:active:text-white"
          >
            {loading ? <SpinIcon /> : 'Desmarcar horario'}
          </button>
        </div>
      </div>
    </Modal>
  );
}
