import TwoSideLayout from 'layout/TwoSideLayout';
import React, { useCallback, useState } from 'react';
import ScheduleSettingsContent from './ScheduleSettingsContent';
import ScheduleSettingsSidenav from './ScheduleSettingsSidenav';

export default function ScheduleSettings(): React.ReactElement {
  const [currentTab, setCurrentTab] = useState(0);
  const onTabChange = useCallback((tabIndex: number) => {
    setCurrentTab(tabIndex);
  }, []);
  return (
    <TwoSideLayout
      leftSide={<ScheduleSettingsSidenav tabIndex={currentTab} onTabChange={onTabChange} />}
      rightRide={<ScheduleSettingsContent tabIndex={currentTab} />}
    />
  );
}
