import React from 'react';

/**
 * Roberto:  https://instagram.fbga3-1.fna.fbcdn.net/v/t51.2885-19/s320x320/134466345_402916361048852_6496940952971135143_n.jpg?_nc_ht=instagram.fbga3-1.fna.fbcdn.net&_nc_ohc=ARDzeKfqalQAX8cnjb2&tp=1&oh=243563500a5928c3cc9ef8940db370e5&oe=6025E1EB
 */

const classes = {
  row: `flex -space-x-2 overflow-hidden px-2 py-2 justify-center my-4 mb-2"`,
  inlineImage: `inline-block h-24 w-24 rounded-full ring-2 ring-white`,
};

export default function Creators() {
  return (
    <div className={classes.row}>
      <a target="_blank" rel="noreferrer" href="https://github.com/sjdonado">
        <img
          className={classes.inlineImage}
          src="https://avatars2.githubusercontent.com/u/27580836?s=460&u=ea6160dab3cec53e38162436adef43f066bd66a1&v=4"
          alt="Juan Rodriguez"
          title="Juan Rodriguez"
        />
      </a>
      <a target="_blank" rel="noreferrer" href="https://github.com/rmacuna">
        <img
          title="Roberto Acuña"
          className={classes.inlineImage}
          src="https://avatars.githubusercontent.com/u/25620714?v=4"
          alt="Roberto Acuña"
        />
      </a>
      <a target="_blank" rel="noreferrer" href="https://instagram.com/wilson__tovar">
        <img
          title="Wilson Tovar"
          className={classes.inlineImage}
          src="https://avatars3.githubusercontent.com/u/18665740?s=400&u=2bdcb049894882990c8f9958f6e83b4e256e50bd&v=4"
          alt=""
        />
      </a>
    </div>
  );
}
