import React from 'react';

import clsx from 'clsx';

import { Subject } from 'library/app-types';

type Props = {
  /** Index del tab activo */
  tabIndex: number;
  /** Funcion para cambiar el tab activo */
  onTabSelected: (tabIndex: number) => void;
  /** Lista de materias agregadas actualmente */
  currentSubjects: Subject[];
};

const tabClasses = {
  wrapper: `block pt-4 pb-4 px-8`,
  container: `flex items-center `,
  tabBase: `px-5 py-2 mr-2 transition-colors relative
  focus:outline-none rounded-lg 
   font-medium tracking-wide text-sm md:text-base`,
  selected: (active: boolean) =>
    `${
      active
        ? `dark:ring-0 dark:ring-offset-transparent bg-blue-50 dark:bg-darkGray-dark text-primary dark:text-gray-50 `
        : `hover:bg-gray-200 text-gray-800 dark:hover:bg-darkGray-light dark:text-whiteLg-light`
    }`,
};

const SearchTabs = React.memo(function SearchTabs(props: Props): React.ReactElement {
  const { tabIndex, onTabSelected, currentSubjects } = props;

  const onSelectTab = (tabIndex: number) => {
    onTabSelected(tabIndex);
  };

  return (
    <div className={tabClasses.wrapper}>
      <div className={tabClasses.container}>
        <button
          onClick={() => onSelectTab(0)}
          className={clsx([tabClasses.tabBase, tabClasses.selected(tabIndex === 0)])}
        >
          Búsqueda
        </button>
        <button
          onClick={() => onSelectTab(1)}
          className={clsx([tabClasses.tabBase, tabClasses.selected(tabIndex === 1)])}
        >
          {currentSubjects.length > 0 ? (
            <span className=" absolute w-6 h-4 right-0 inset-y-0 bg-red-500 text-white text-xs font-medium rounded-full">
              {currentSubjects.length}
            </span>
          ) : null}
          Materias agregadas
        </button>
      </div>
    </div>
  );
});

export default SearchTabs;
