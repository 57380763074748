import Alert from 'components/Alert';
import Button from 'components/Button';
import SectionDivider from 'components/SectionDivider';
import Table from 'components/table/Table';
import { useModal } from 'hooks/useModal';
import React, { useMemo, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CurrentSubjectsList from '../schedule-generate/current-groups-modal/CurrentGroupList';
import DownloadScheduleDropdown from '../schedule-generate/DownloadScheduleDropdown';
import TotalSameSchedulesLabel from './TotalSameSchedulesLabel';
import UnpinModal from './UnpinModal';

const classes = {
  infoCard: `border border-gray-200  mt-4 divide divide-y divide-gray-200 dark:divide-darkGray-light dark:border-darkGray-light rounded-lg px-4 py-4`,
  infoCardSection: `mt-2 pt-2 divide divide-y divide-gray-200 dark:divide-darkGray-light`,
  infoCardSectionItem: `flex flex-col sm:flex-row md:items-center py-2 justify-between`,
};

function ScheduleFavsContent() {
  const currentUserSchedule = useSelector(
    (state: RootState) => state.schedules.currentUserSchedule,
  );
  const { modalOpen, toggle } = useModal({ initialMode: false });

  const groups = useMemo(() => {
    return currentUserSchedule?.groups;
  }, [currentUserSchedule]);

  const checkIfHaveEmptyGroups = () => {
    return !!groups?.some(({ quota: { free } }) => free === 0);
  };

  return (
    <Fragment>
      {modalOpen && <UnpinModal toggle={toggle} isOpen={modalOpen} />}
      <div className="px-4 pt-2 pb-52">
        {currentUserSchedule && (
          <Fragment>
            <div className="mt-2">
              <Table isReadOnly currentSchedule={currentUserSchedule.groups} />
            </div>
            <div className="mt-2"></div>
            {checkIfHaveEmptyGroups() ? (
              <Alert
                title="Que mal, parece que este horario ya no sirve"
                message="¡Este horario tiene una materia a la cual se le llenaron los cupos! Te recomendamos no desanimarte... ¡puede ocurrir un milagro!"
              />
            ) : null}

            <SectionDivider title="Información" />
            {/* Esto podría separarse en un componente */}
            <div className={classes.infoCard}>
              <section>
                <h1 className="font-medium leading-6 text-gray-900 dark:text-whiteLg-high">
                  Lista de materias
                </h1>
                <div className="flex mb-2">
                  <em className="text-xs text-gray-700 dark:text-whiteLg">
                    Lista de materias agregadas a este horario cuando lo marcaste, puede que los
                    cupos hayan variado
                  </em>
                </div>
              </section>
              <section className="mt-2 pt-4">
                {groups && <CurrentSubjectsList readOnly currentSchedule={groups} />}
              </section>
              <TotalSameSchedulesLabel total={currentUserSchedule.total} />
            </div>
            <div className="mt-4"></div>
            <SectionDivider title="Acciones" />
            <div className={classes.infoCard}>
              <section>
                <h1 className="font-medium leading-6 text-gray-900 dark:text-whiteLg-high">
                  Lista de acciones
                </h1>
                <div className="flex mb-2">
                  <em className="text-xs text-gray-700 dark:text-whiteLg">
                    Desmarca este horario, descárgalo o expórtalo
                  </em>
                </div>
              </section>
              <section className={classes.infoCardSection}>
                <div className={classes.infoCardSectionItem}>
                  <div className="flex-col">
                    <h3 className="font-medium text-sm text-gray-900 dark:text-whiteLg-high">
                      Desmarcar horario
                    </h3>
                    <p className="text-sm text-gray-700 dark:text-whiteLg mt-2 mb-2 sm:mt-0 sm:mb-0">
                      Desmarca este horario si ya no te sirve o los cupos estan desactualizados.
                    </p>
                  </div>
                  <div>
                    <Button onClick={toggle} size="normal" variant="error">
                      Desmarcar
                    </Button>
                  </div>
                </div>
                <div className={classes.infoCardSectionItem}>
                  <div className="flex-col  sm:flex-row md:items-center  justify-between ">
                    <h3 className="font-medium text-sm text-gray-900 dark:text-whiteLg-high">
                      Descargar
                    </h3>
                    <p className="text-gray-700 dark:text-whiteLg text-sm mt-2 mb-2 sm:mt-0 sm:mb-0">
                      Descarga este horario en PNG, CSV o PDF <strong>(Recomendado)</strong>
                    </p>
                  </div>
                  <div className="flex flex-row-reverse sm:flex-row ">
                    {groups && (
                      <DownloadScheduleDropdown
                        customName={currentUserSchedule.name}
                        currentSchedule={groups}
                      />
                    )}
                  </div>
                </div>
              </section>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default ScheduleFavsContent;
