import SectionDivider from 'components/SectionDivider';
import React, { useCallback } from 'react';
import alternate from 'assets/images/alternate.png';
import dark from 'assets/images/Dark.png';
import light from 'assets/images/Light.png';
import AppereanceBox from './AppereanceBox';
import useDarkMode from 'hooks/useDarkMode';

function SettingsAppereance(): React.ReactElement {
  const [theme, setMode] = useDarkMode();

  /**
   * Cambia la apariencia en localstorage del tema que seleccione el usuario
   */
  const setAppereanceMode = useCallback(
    (mode: string) => {
      setMode(mode);
    },
    [setMode],
  );

  return (
    <div>
      <SectionDivider title="Tema de la aplicación" />
      <p className="text-sm text-gray-600 dark:text-whiteLg pt-2 mb-4">
        Configura la apariencia que tiene Cronun. Escoge si te gusta la tonalidad oscura, la clara o
        si quieres que tome la configuración de tu dispositivo
      </p>
      <div className="flex justify-between flex-col md:flex-row">
        <AppereanceBox
          setMode={setAppereanceMode}
          theme={theme}
          mode="media"
          label="Por defecto del sistema"
          image={alternate}
        />
        <AppereanceBox
          setMode={setAppereanceMode}
          theme={theme}
          mode="dark"
          label="Modo oscuro"
          image={dark}
        />
        <AppereanceBox
          setMode={setAppereanceMode}
          theme={theme}
          mode="light"
          label="Modo claro"
          image={light}
        />
      </div>
    </div>
  );
}

export default SettingsAppereance;
