import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { Group, UserSchedule } from './app-types';
import { parseGroups } from './parseGroups';
import { setUserSchedules, setUserScheduleMetadata } from '../redux/slices/schedulesSlice';

/**
 * Helper para saber si estoy en una Mac o en un Windows.
 */
export function isMacintosh() {
  return navigator.platform.indexOf('Mac') > -1;
}

/**
 * Toma los grupos relacionados con las materias y devuelve los profesores
 * sin repetir con sus respectivos grupos.
 * @param groups Arreglo de grupos relacionados a la materia
 */
export const parseProfessorGroups = (groups: Group[]) => {
  const hashMap = new Map();
  for (let group of groups) {
    const key = group.professors.map(({ name }) => name).join(', ');
    if (hashMap.has(key)) {
      hashMap.set(key, [...hashMap.get(key), group]);
    } else {
      hashMap.set(key, [group]);
    }
  }
  const parsedGroups = Array.from(hashMap).map(([professor, groups]) => ({
    professor,
    groups,
  }));
  return parsedGroups;
};

/**
 * Esta funcion descarga la imagen que acabas de crear con html2canvas
 * @param uri Canvas data url
 * @param filename Nombre del archivo a guardar
 */
export function saveAs(uri: string, filename: string) {
  var link = document.createElement('a');

  if (typeof link.download === 'string') {
    link.href = uri;
    link.download = filename;

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
}
/**
 * Guarda un horario en el store parseando los datos necesarios como
 * grupos y la metadata necesaria para mostrarlo correctamente.
 *
 * @param userSchedules Lista de horarios guardados
 * @param dispatch Dispatcher para redux
 */
export const saveUserSchedules = (
  userSchedules: UserSchedule[] | undefined,
  dispatch: Dispatch<AnyAction>,
) => {
  const userSchedulesMetadata = [] as UserSchedule[];

  const parsedUserSchedules = userSchedules?.map((userSchedule) => {
    userSchedulesMetadata.push({
      id: userSchedule.id,
      name: userSchedule.name,
      total: userSchedule.total,
      createdAt: userSchedule.createdAt,
      groups: parseGroups(userSchedule.groups),
    });
    return {
      ...userSchedule,
      groups: parseGroups(userSchedule.groups),
    };
  });
  if (parsedUserSchedules) {
    const groups = parsedUserSchedules.map((group: UserSchedule) => {
      return group.groups;
    });
    dispatch(setUserSchedules(groups));
    dispatch(setUserScheduleMetadata(userSchedulesMetadata));
  }

  return parsedUserSchedules;
};
