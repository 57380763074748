import { gql } from '@apollo/client';

/**
 * Obtiene los grupos de una materia
 */
export default gql`
  query getSubjectGroupsQuery($subjectId: ID!) {
    getSubjectGroups(subjectId: $subjectId) {
      id
      nrc
      scheduleType
      groupNumber
      professors {
        id
        name
        department {
          name
          code
        }
      }
      subject {
        id
        name
        mat
      }
      schedule {
        time {
          start
          end
        }
        place
        day
        startDate
        endDate
      }
      quota {
        taken
        free
      }
      bg
      font
    }
  }
`;
