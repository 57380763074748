import clsx from 'clsx';
import useRouter from 'hooks/useRouter';
import React from 'react';

const classes = {
  baseTab: `mr-12 w-auto text-sm focus:outline-none group relative pb-2`,
  mobileHidden: `hidden md:flex`,
};
function SubBarTabs() {
  const { location, history, match } = useRouter();

  const handleSelectRoute = (route: string) => {
    switch (route) {
      case 'schedules':
        history.push('/schedules');
        break;
      case 'favorites':
        history.push(`${match.url}/favorites`);
        break;
      case 'filters':
        history.push(`${match.url}/filters`);
        break;
      case 'settings':
        history.push(`${match.url}/settings`);
        break;
    }
  };

  return (
    <div className="flex absolute bottom-0">
      <button
        onClick={() => handleSelectRoute('schedules')}
        className={clsx([
          classes.baseTab,
          `flex`,
          location.pathname === '/schedules' && `border-b-2 border-secondary dark:text-white`,
        ])}
      >
        <div className="w-5 h-5 text-gray-700 dark:text-whiteLg mr-1 group-hover:text-gray-900 dark:group-hover:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
          </svg>
        </div>
        <span className=" group-hover:text-gray-900 dark:text-whiteLg-high  dark:group-hover:text-gray-50 text-sm">
          Todos <span className="hidden md:inline-block">los horarios</span>
        </span>
      </button>

      <button
        onClick={() => handleSelectRoute('filters')}
        className={clsx([
          classes.baseTab,
          `flex`,
          location.pathname === '/schedules/filters' && `border-b-2 border-secondary`,
        ])}
      >
        <div className="w-5 h-5 text-gray-700 dark:text-whiteLg mr-1 group-hover:text-gray-900 dark:group-hover:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
            />
          </svg>
        </div>
        <span className=" group-hover:text-gray-900 dark:text-whiteLg-high dark:group-hover:text-gray-50 text-sm">
          Filtrar <span className="hidden md:inline-block">materias</span>
        </span>
      </button>
      <button
        onClick={() => handleSelectRoute('favorites')}
        className={clsx([
          classes.baseTab,
          classes.mobileHidden,
          location.pathname === '/schedules/favorites' && `border-b-2 border-secondary`,
        ])}
        disabled
      >
        <div className="w-5 h-5 text-gray-700 dark:text-whiteLg mr-1 group-hover:text-gray-900 dark:group-hover:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
            />
          </svg>
        </div>
        <span className=" group-hover:text-gray-900 dark:text-whiteLg-high dark:group-hover:text-gray-50 text-sm">
          Favoritos
        </span>
      </button>
      <button
        onClick={() => handleSelectRoute('settings')}
        className={clsx([
          classes.baseTab,
          classes.mobileHidden,
          location.pathname === '/schedules/settings' && `border-b-2 border-secondary`,
        ])}
      >
        <div className="w-5 h-5 text-gray-700 dark:text-whiteLg mr-1 group-hover:text-gray-900 dark:group-hover:text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </div>
        <span className=" group-hover:text-gray-900 dark:text-whiteLg-high dark:group-hover:text-gray-50 text-sm">
          Configuraciones
        </span>
      </button>
    </div>
  );
}

export default React.memo(SubBarTabs);
