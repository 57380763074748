import { isMacintosh } from 'library/helpers';
import React, { Fragment } from 'react';

type CommandProps = {
  /** Letra para presionar en el comando */
  letterCommand?: string;
  /** Renderizar una sola letra ESC */
  singleCommand?: string;
};
export default React.memo(function CommandButton(props: CommandProps) {
  let renderSingleCommand = props.singleCommand ? (
    <Fragment>
      <span className="sr-only">Press</span>
      <kbd title={props.singleCommand ? props.singleCommand : 'K'}>
        {props.singleCommand ? props.singleCommand : 'K'}
      </kbd>
      <span className="sr-only">to quit</span>
    </Fragment>
  ) : null;

  let renderDefaultCommand = (
    <Fragment>
      <span className="sr-only">Press</span>
      {isMacintosh() ? (
        <kbd className="font-sans">
          <abbr title="Command" className="no-underline">
            &#8984;
          </abbr>
        </kbd>
      ) : (
        <kbd className="font-sans">
          <abbr title="Ctrl" className="no-underline">
            Ctrl
          </abbr>
        </kbd>
      )}
      <span className="sr-only">and</span>
      <kbd title={props.letterCommand ? props.letterCommand : 'K'}>
        {props.letterCommand ? props.letterCommand : 'K'}
      </kbd>
      <span className="sr-only">to search</span>
    </Fragment>
  );

  return (
    <span className="hidden group-hover:text-gray-600 dark:group-hover:text-gray-200 sm:block text-gray-400  dark:text-whiteLg text-sm leading-5 py-0.5 px-1.5 border border-gray-300 dark:border-darkGray-light rounded-md">
      {props.singleCommand ? renderSingleCommand : renderDefaultCommand}
    </span>
  );
});
