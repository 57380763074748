import React from 'react';
import { mixpanel } from 'service/mixpanel';

type Props = {
  /** Label que describe la apariencia a tomar */
  label: string;
  /** Imagen que describe la apariencia a tomar */
  image: string;
  /** Current mode */
  mode: string;
  /** Tema actual de la aplicacion */
  theme: string;
  /** Change mode */
  setMode: (mode: string) => void;
};

function AppereanceBox(props: Props): React.ReactElement<Props> {
  const { label, image, mode, theme, setMode } = props;

  const handleSetMode = () => {
    if (mode === 'media') {
      localStorage.removeItem('theme');
      setMode('media');
    } else {
      setMode(mode);
    }

    mixpanel.track('Set Appareance', {
      appareanceMode: mode,
    });
  };

  return (
    <figure className="flex flex-col mr-4 mb-2 md:mb-0 border border-gray-200 dark:border-darkGray-light rounded-lg">
      <div className="flex-grow flex-shrink-0">
        <img className="w-full h-auto rounded-t-lg" src={image} alt={label} />
      </div>
      <div className="border-t flex items-center px-4 py-2 border-gray-200 dark:border-darkGray-light">
        <input type="radio" className="mr-2" checked={theme === mode} onChange={handleSetMode} />
        <span className="text-sm text-gray-800 font-medium dark:text-gray-100">{label}</span>
      </div>
    </figure>
  );
}

export default AppereanceBox;
