import { createSlice } from '@reduxjs/toolkit';

type AppState = {
  isLoadingNewSubject: boolean;
  isLoadingGeneratingSchedules: boolean;
  generateToastError: boolean;
};
const initialState = {
  isLoadingNewSubject: false,
  isLoadingGeneratingSchedules: false,
  generateToastError: false,
} as AppState;

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoadingAddingSubject(state, action) {
      state.isLoadingNewSubject = action.payload;
    },
    setLoadingGeneratingSchedules(state, action) {
      state.isLoadingGeneratingSchedules = action.payload;
    },
    setGenerateToast(state, action) {
      state.generateToastError = action.payload;
    },
  },
});

const { reducer, actions } = appSlice;

export const { setLoadingAddingSubject, setLoadingGeneratingSchedules, setGenerateToast } = actions;
// export const {} = actions;
export default reducer;
