import mixpanel from 'mixpanel-browser';

mixpanel.init('0a65a770fe6fcb45955a080dd8be36fd');

// Dummy mixpanel
/**
const mixpanel = {
    track: (arg: any, arg2?: any) => {},
    identify: (arg: any) => {},
    reset: () => {},
    people: {
        set: (arg: any) => {},
    }
};
 */

export { mixpanel };
