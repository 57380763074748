import { dataHeaders, tableRows } from 'library/constants';
import { getScheduleMatrix } from 'library/getScheduleMatrix';
import React, { Fragment, useMemo } from 'react';
import { SelectableGroup } from 'react-selectable-fast';
import TableSelectableItem from './TableSelectableItem';

type Props = {
  /** Horario actual generado */
  currentSchedule: any[];
  /** Referencia a la tabla para poder eliminar los filtros programáticamente */
  tableRef?: any;
  /** Cuando está activada la tabla se vuelve de solo lectura */
  isReadOnly?: boolean;
  /** Evento que se dispara una vez dejas de seleccionar en la tabla */
  onStopSelecting?: () => void;
};

const tableClasses = {
  baseTd: `border border-gray-200 dark:border-darkGray-light  px-3 w-32 h-8 cursor-pointer 
  whitespace-nowrap text-gray-800 overflow-hidden overflow-ellipsis break-all text-xs bg-white dark:bg-darkGray  hover:bg-gray-50 dark:hover:bg-darkGray-light`,
  table: `border-collapse sm:table-auto md:table-fixed w-full whitespace-no-wrap bg-white dark:bg-darkGray table-striped relative`,
  tdHour: `not-selectable border border-gray-200 dark:border-darkGray-light dark:text-white px-3 text-xs w-32 text-center select-none`,
  th: `sticky top-0 border-b border-r border-gray-200 dark:border-darkGray-light px-6 py-2 text-gray-600 dark:text-gray-200 font-bold tracking-wider uppercase text-xs`,
};

function Table(props: Props): React.ReactElement<Props> {
  const { currentSchedule, tableRef, isReadOnly, onStopSelecting } = props;

  /**
   * Genera una tabla con celdas vacías
   */
  const emptyTable = () =>
    tableRows.data.map((row) => {
      const { id, hour } = row;
      return (
        <tr key={id}>
          <td className="border border-gray-200 dark:border-darkGray-light px-3 dark:text-white text-xs w-32 text-center select-none not-selectable">
            {hour}
          </td>
          {new Array(6).fill(null).map((_, pos) => (
            <td key={pos} className={tableClasses.baseTd}></td>
            // <TableSelectableItem key={pos} matrixPosValue={null} />
          ))}
        </tr>
      );
    });

  /**
   * Renderiza los encabezados de la tabla
   */
  const renderTableHeaders = useMemo(() => {
    let header = Object.values(dataHeaders.headers);
    return header.map((key, index) => {
      return (
        <th className={tableClasses.th} key={index}>
          {key.name}
        </th>
      );
    });
  }, []);

  /**
   * Renderiza las materias en el cuerpo de la tabla
   */
  const renderTableData = useMemo(() => {
    if (currentSchedule.length > 0) {
      const matrix = getScheduleMatrix(currentSchedule);

      if (!matrix) {
        return emptyTable();
      }

      return tableRows.data.map((row, index) => {
        const { id, hour } = row;
        return (
          <tr key={id}>
            <td className={tableClasses.tdHour}>{hour}</td>
            {new Array(6).fill(null).map((_, pos) => {
              return (
                <TableSelectableItem
                  posIndex={`${pos},${index}`}
                  key={pos}
                  matrixPosValue={matrix[pos][index]}
                />
              );
            })}
          </tr>
        );
      });
    } else {
      return emptyTable();
    }
  }, [currentSchedule]);

  const renderReadOnlyTableData = useMemo(() => {
    if (currentSchedule.length > 0) {
      const matrix = getScheduleMatrix(currentSchedule);
      if (!matrix) {
        return emptyTable();
      }
      return tableRows.data.map((row, index) => {
        const { id, hour } = row;
        return (
          <tr key={id}>
            <td className={tableClasses.tdHour}>{hour}</td>
            {new Array(6).fill(null).map((_, pos) => (
              <td
                className="border border-gray-200 dark:border-darkGray-light px-3 w-32 h-8 cursor-pointer 
              whitespace-nowrap text-gray-800 dark:text-whiteLg-high overflow-hidden overflow-ellipsis break-all text-xs"
                key={pos}
              >
                {matrix[pos][index]}
              </td>
            ))}
          </tr>
        );
      });
    } else {
      return emptyTable();
    }
  }, [currentSchedule]);

  return isReadOnly ? (
    <Fragment>
      <div className=" sm:overflow-x-auto md:overflow-x-hidden bg-white rounded-lg shadow  overflow-y-auto relative">
        <table id="table-to-print" className={tableClasses.table}>
          <thead className="not-selectable">
            <tr className="text-center">{renderTableHeaders}</tr>
          </thead>
          <tbody>{renderReadOnlyTableData}</tbody>
        </table>
      </div>
    </Fragment>
  ) : (
    <SelectableGroup
      ref={tableRef}
      className="main sm:overflow-x-auto md:overflow-x-hidden bg-white rounded-lg shadow  overflow-y-auto relative"
      clickClassName="tick"
      enableDeselect
      tolerance={1}
      globalMouse
      allowClickWithoutSelected={true}
      onSelectionFinish={onStopSelecting}
      ignoreList={['.not-selectable']}
    >
      <Fragment>
        <table id="table-to-print" className={tableClasses.table}>
          <thead className="not-selectable">
            <tr className="text-center">{renderTableHeaders}</tr>
          </thead>
          <tbody>{renderTableData}</tbody>
        </table>
      </Fragment>
    </SelectableGroup>
  );
}

export default React.memo(Table);
