import Modal from 'components/Modal';
import { ModalProps } from 'library/app-types';
import CloseIcon from 'library/icons/CloseIcon';
import React from 'react';
import Contributors from './Contributors';
import Creators from './Creators';

const modalClasses = {
  panelContainer: `relative w-full md:w-8/12 my-6 mx-auto max-w-5xl  md:max-w-5xl`,
  panelContent: `border-0 divide-y divide-gray-200 dark:divide-darkGray-light rounded-3xl shadow-lg relative flex flex-col w-full bg-white dark:bg-darkGray outline-none focus:outline-none `,
};

export default function AboutModal(props: ModalProps) {
  const { isOpen, toggle } = props;
  return (
    <Modal onClose={toggle} isOpen={isOpen}>
      {/* Panel */}
      <div className={modalClasses.panelContainer}>
        <div className={modalClasses.panelContent}>
          <section className="px-8 pt-8 py-6">
            <div className="flex justify-between">
              <h1 className="text-2xl text-gray-900 dark:text-gray-50 font-semibold leading-6">
                ¡Gracias por permitirnos impactar en su experiencia académica! 🥰
              </h1>
              <button onClick={toggle} className="w-5 h-5 mr-2 text-gray-500">
                <CloseIcon />
              </button>
            </div>
          </section>
          <section className="px-8 py-6  overflow-y-auto max-h-580 relative">
            <article>
              <section className="pt-2">
                <h2 className="text-lg dark:text-white">
                  Queremos darle las gracias a los más de 10.000 estudiantes que confiaron en
                  nosotros y usaron la aplicación. Ustedes fueron el propósito de muchos días y
                  noches de trabajo.
                  <br />
                  <br />
                </h2>

                <p className="text-gray-800 dark:text-whiteLg-high pt-2">
                  <b>
                    Por petición del Departamento TI de la Universidad del Norte Cronun no estará
                    disponible este semestre.&nbsp;
                  </b>
                  La información de las materias que presentamos aquí está desactualizada, es
                  únicamente para fines demostrativos y <b>NO</b> deberían utilizarse para armar
                  nuevos horarios.
                  <br /> <br />
                </p>

                <p className="text-gray-800 dark:text-whiteLg-high pt-2">
                  Si consideras que una herramienta similar a Cronun es de gran utilidad en tu vida
                  académica te invitamos a usar el
                  <b>
                    <a href="https://www.uninorte.edu.co/sugerencias"> Buzón de Sugerencias</a>{' '}
                  </b>{' '}
                  de la universidad para manifestar la oportunidad de mejora.
                  <br />
                  <br />
                </p>

                <p className="text-gray-800 dark:text-whiteLg-high pt-2">
                  Para terminar, ten en cuenta que copiar el código fuente de CRONUN sin previo
                  permiso será considerado como plagio.
                </p>

                <Creators />

                <span className="inline-flex w-full text-center pt-2 flex-col">
                  <span className="pr-2 text-gray-800 dark:text-gray-100">
                    Juan Rodriguez{' '}
                    <a
                      className="text-blue-500 dark:text-blue-200"
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/sjdonado/"
                    >
                      (@sjdonado)
                    </a>
                  </span>
                  <span className="pr-2 text-gray-800 dark:text-gray-100">
                    Roberto Acuña{' '}
                    <a
                      className="text-blue-500 dark:text-blue-200"
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/rmacuna99/"
                    >
                      (@rmacuna99)
                    </a>
                  </span>
                  <span className="text-gray-800 dark:text-gray-100">
                    Wilson Tovar{' '}
                    <a
                      className="text-blue-500 dark:text-blue-200"
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/wilson__tovar/"
                    >
                      (@wilson__tovar)
                    </a>
                  </span>
                </span>

                <span className="flex justify-center text-center">
                  <strong className="block text-gray-800 dark:text-gray-100">
                    <em>Creadores de Cronun</em>
                  </strong>
                </span>

                <div className="w-full border-b border-gray-200 dark:border-darkGray-light py-2"></div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-gray-50 py-2">
                  Agradecimientos
                </h3>
                <Contributors />

                <p className="text-gray-800 dark:text-whiteLg">
                  Queremos darle las gracias a los contribuidores que nos ayudaron a resolver
                  problemas cuando más lo necesitábamos. Estas personas aportaron su granito de
                  arena para que una comunidad de más de 10.000 estudiantes siguiera disfrutando de
                  la aplicación.
                </p>

                {/* <div className="w-full border-b border-gray-200 dark:border-darkGray-light py-2"></div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-gray-50 py-2">
                  ¿Tienes problemas?
                </h3>
                <p className="text-gray-800 dark:text-whiteLg">
                  Si tienes algún problema o pregunta no dudes en{' '}
                  <a
                    className="text-blue-500 dark:text-blue-200"
                    rel="noreferrer"
                    target="_blank"
                    href="https://chat.whatsapp.com/KC7pOGeCUUb4kT7Di75NId"
                  >
                    unirte al grupo de WhatsApp de soporte haciendo click aquí.{' '}
                  </a>
                </p>

                <div className="w-full border-b border-gray-200 dark:border-darkGray-light py-2"></div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-gray-50 py-2">
                  ¿Y la clasificación de docentes?
                </h3>
                <p className="text-gray-800 dark:text-whiteLg">
                  Esta funcionalidad no salió a la luz porque hay que hacer ajustes en coordinación
                  con la universidad debido a la complejidad propia del ejercicio de evaluación de
                  los docentes.
                </p> */}
              </section>
            </article>
          </section>
        </div>
      </div>
    </Modal>
  );
}
