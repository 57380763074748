import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_EMPTY_SCHEDULE } from 'library/constants';
import { generateEmptyMatrix } from 'library/generateEmptyMatrix';
import { Group, UserSchedule } from 'library/app-types';

type SchedulesState = {
  schedules: [];
  currentSchedule: Group[];
  currentPage: number;
  matrixTemplate: any[];
  allowFullGroups: boolean;
  userSchedules: Group[][];
  userSchedulesMetadata: UserSchedule[];
  currentUserSchedule: UserSchedule | null;
  mySchedule: any;
};

const userPreferences = localStorage.getItem('userPreferences');

const initialState = {
  schedules: [],
  currentSchedule: DEFAULT_EMPTY_SCHEDULE,
  currentPage: -1,
  matrixTemplate: generateEmptyMatrix(),
  allowFullGroups: userPreferences ? JSON.parse(userPreferences + '').allowFullGroups : true,
  userSchedules: [],
  userSchedulesMetadata: [],
  currentUserSchedule: null,
  mySchedule: null,
} as SchedulesState;

const setCurrentSchedule = (state: SchedulesState, page: number) => {
  if (state.schedules.length === 0) {
    state.currentPage = -1;
    // state.currentSchedule = DEFAULT_EMPTY_SCHEDULE;
    // state.matrixTemplate = generateEmptyMatrix();
    return;
  }

  if (page < 0 || page >= state.schedules.length) {
    return;
  }

  state.currentPage = page;
  state.currentSchedule = state.schedules[page];
};

const scheduleSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    setSchedules(state, action) {
      state.schedules = action.payload;
      setCurrentSchedule(state, 0);
    },
    setCurrentPage(state, action) {
      setCurrentSchedule(state, action.payload);
    },
    setUserSchedules(state, action) {
      state.userSchedules = action.payload;
    },
    setUserScheduleMetadata(state, action) {
      state.userSchedulesMetadata = action.payload;
    },
    setCurrentUserSchedule(state, action) {
      state.currentUserSchedule = action.payload;
      // state.currentUserScheduleMetadata = state.userSchedulesMetadata[state.userSchedules.indexOf(state.cu)];
    },
    setMatrixTemplate(state, action) {
      state.matrixTemplate = action.payload;
    },
    setEmptyMatrixTemplate(state, action) {
      state.matrixTemplate = DEFAULT_EMPTY_SCHEDULE;
    },
    setAllowFullGroups(state, action) {
      state.allowFullGroups = action.payload;
    },
    removeAllSchedules(state) {
      state.schedules = [];
      state.currentSchedule = DEFAULT_EMPTY_SCHEDULE;
    },
    removeUserSchedules(state, action) {
      const index = state.userSchedulesMetadata.findIndex(
        (userSchedule) => userSchedule.id === action.payload,
      );
      if (index > -1) {
        state.userSchedules.splice(index, 1);
        state.userSchedulesMetadata.splice(index, 1);
        state.currentUserSchedule = null;
      }
    },
    setMySchedule(state, action) {},
  },
});

const { actions, reducer } = scheduleSlice;
export const {
  removeAllSchedules,
  removeUserSchedules,
  setAllowFullGroups,
  setCurrentPage,
  setCurrentUserSchedule,
  setMatrixTemplate,
  setMySchedule,
  setSchedules,
  setUserScheduleMetadata,
  setUserSchedules,
} = actions;

export default reducer;
