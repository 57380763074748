import Modal from 'components/Modal';
import { Group, ModalProps, ToastContent } from 'library/app-types';
import CloseIcon from 'library/icons/CloseIcon';
import CopyIcon from 'library/icons/CopyIcon';
import React, { useMemo, useState } from 'react';
import ToolbarButton from '../ToolbarButton';
import CurrentGroupList from './CurrentGroupList';
// import * as copy from 'copy-to-clipboard';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useToast } from '@chakra-ui/toast';
import Toast from 'components/Toast';

type Props = ModalProps & {
  currentSchedule: Group[];
};

const modalClasses = {
  panelContainer: `relative w-full lg:w-5/12 my-6 mx-auto md:max-w-3xl`,
  panelContent: `border-0 divide-y divide-gray-200 dark:divide-darkGray-light rounded-3xl shadow-lg relative flex flex-col w-full bg-white dark:bg-darkGray outline-none focus:outline-none `,
};

function CurrentGroupsModal(props: Props) {
  const { isOpen, toggle, currentSchedule } = props;
  const [nrcs] = useState(currentSchedule.map(({ nrc }) => nrc).join(','));
  const toast = useToast();

  const displayToastMessage = (content: ToastContent) => {
    toast({
      render: (props) => {
        return <Toast onClose={props.onClose} {...content} />;
      },
    });
  };

  /**
   * Copia los NRCs de las materias agregadas
   */
  const handleCopyNrc = () => {
    if (currentSchedule.length > 0) {
      displayToastMessage({
        title: 'Códigos copiados',
        description: `Copiaste los códigos: ${nrcs}`,
        type: 'good',
      });
    }
  };

  const currentGroupList = useMemo(() => {
    return <CurrentGroupList readOnly={false} currentSchedule={currentSchedule} />;
  }, [currentSchedule]);

  return (
    <Modal isOpen={isOpen} onClose={toggle}>
      <div className={modalClasses.panelContainer}>
        <div className={modalClasses.panelContent}>
          <section className="px-8 pt-8 pb-2">
            <div className="flex justify-between">
              <h1 className="text-2xl text-gray-900 dark:text-gray-50 font-semibold leading-6">
                Lista de materias agregadas
              </h1>
              <button onClick={toggle} className="w-5 h-5 mr-2 text-gray-500">
                <CloseIcon />
              </button>
            </div>
            <span className="text-gray-500 dark:text-whiteLg text-sm ">
              {currentSchedule.length} materias
            </span>
          </section>
          <section className="px-8 py-4 overflow-y-auto max-h-480 md:max-h-580">
            {currentGroupList}
          </section>
          <div className="flex justify-end px-8 py-4 bg-gray-50 dark:bg-darkGray-dark rounded-b-3xl">
            <ToolbarButton
              onClick={toggle}
              renderRightLabel={false}
              icon={<CloseIcon width={20} height={20} />}
              title="Cerrar"
            />
            <CopyToClipboard onCopy={handleCopyNrc} text={nrcs}>
              <ToolbarButton renderRightLabel={false} icon={<CopyIcon />} title="Copiar NRCs" />
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default CurrentGroupsModal;
