import Checkbox from 'components/Checkbox';
import Tag from 'components/Tag';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Group } from 'library/app-types';
// import { generateEmptyMatrix } from 'library/generateEmptyMatrix';
import CloseIcon from 'library/icons/CloseIcon';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
// import { removeAllSchedules, setMatrixTemplate } from 'redux/slices/schedulesSlice';
import { saveGroupStatus, updateGroupStatuses } from 'redux/slices/subjectsSlice';
import { RootState } from 'redux/store';
import ProfessorsNames from './ProfessorsNames';
import SubjectProfessorGroups from './SubjectProfessorGroups';

type Props = {
  groups: Group[];
  professors: string;
};

function SubjectProfessor(props: Props) {
  const { groups, professors } = props;
  const [isToggledGroups, setIsToggledGroups] = useState(false);
  const dispatch = useAppDispatch();
  const { groupStatuses } = useSelector((state: RootState) => state.subjects);

  // Si estas filtrando y te quedaste sin horarios, manda este efecto a correr a que limpie la tabla.
  // useEffect(() => {
  //   if (groupStatuses.length > 0) {
  //     if (schedules.length === 0) {
  //       dispatch(setMatrixTemplate(EMPTY_MATRIX));
  //       dispatch(removeAllSchedules());
  //     }
  //   }
  // }, [groupStatuses, dispatch, schedules.length]);

  /** Bloquea un profesor con sus grupos completamente */
  const onBlockProfessor = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        saveGroupStatus({
          groupsNrc: groups.map(({ nrc }) => nrc),
          blocked: e.target.checked,
        }),
      );
      dispatch(updateGroupStatuses());
    },
    [dispatch, groups],
  );
  /** Calcula los statuses de los grupos solo cuando cambien o los groupStatuses o groups */
  const parsedGroupStatuses = useMemo(() => {
    return groups.map((group) => {
      let groupWithStatusCode: Group = { ...group };
      const foundGroup = groupStatuses.find(({ nrc }) => nrc === group.nrc);
      if (foundGroup) {
        groupWithStatusCode['blocked'] = foundGroup.blocked;
      } else {
        groupWithStatusCode['blocked'] = false;
      }
      return groupWithStatusCode;
    });
  }, [groupStatuses, groups]);

  // Determina si todos los checkbox de un profesor estan bloqueados
  const isProfessorBlocked = useMemo(() => {
    return parsedGroupStatuses.every((elem) => elem.blocked);
  }, [parsedGroupStatuses]);

  /** Calcula cuantos grupos se han bloqueado para poder mostrarlo posteriormente */
  const blockedGroups = useMemo(() => {
    let groupsBlocked = 0;
    parsedGroupStatuses.forEach((elem) => {
      if (elem.blocked) {
        groupsBlocked++;
      }
    });
    return groupsBlocked;
  }, [parsedGroupStatuses]);

  return (
    <Fragment>
      <div className="d-block bg-gray-50 dark:bg-darkGray p-4 py-4 mb-2 tracking-wide rounded-lg border border-gray-200 dark:border-darkGray-light cursor-pointer dark:hover:bg-darkGray-light hover:bg-gray-100">
        <div className="flex ">
          <Checkbox onChange={onBlockProfessor} checked={isProfessorBlocked} />
          <div
            onClick={() => setIsToggledGroups((prevState) => !prevState)}
            className="flex items-center flex-1"
          >
            {/* Checkbox */}
            <div className="flex flex-col md:flex-row items-start md:items-center">
              <ProfessorsNames viewMode={0} professors={professors} />
              {isProfessorBlocked ? <Tag title="Bloqueado" type="error" /> : null}
              {blockedGroups && !isProfessorBlocked ? (
                <Tag title={`${blockedGroups} Bloqueados`} type="warning" />
              ) : null}
            </div>
          </div>

          <button
            onClick={() => setIsToggledGroups((prevState) => !prevState)}
            className="flex-shrink-0 w-5 h-5 text-gray-900 dark:text-gray-50"
          >
            {isToggledGroups ? (
              <CloseIcon width={20} height={20} />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      {/* Show/Hide based on state */}
      {isToggledGroups && <SubjectProfessorGroups groups={parsedGroupStatuses} />}
    </Fragment>
  );
}

export default SubjectProfessor;
