import { useAppPreferences } from 'hooks/useAppPreferences';
import MinimalLayout from 'layout/MinimalLayout';
import ClassicLayout from 'layout/ClassicLayout';
import { Route, RouteProps } from 'react-router-dom';

// Renderiza una ruta condicionalmente dado las preferencias del usuario.
function LayoutRoute({ component: Component, ...rest }: RouteProps) {
  const [preferences] = useAppPreferences();
  if (!Component) return null;
  return (
    <Route
      {...rest}
      render={(props) =>
        preferences.viewMode === 0 ? (
          <MinimalLayout>
            <Component {...props} />
          </MinimalLayout>
        ) : (
          <ClassicLayout>
            <Component {...props} />
          </ClassicLayout>
        )
      }
    />
  );
}

export default LayoutRoute;
