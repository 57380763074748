import React, { Fragment, useState } from 'react';
import Logo from 'assets/images/logo512.png';
import SubjectSearchButton from 'pages/schedule/SubjectSearchButton';
import AppbarDropdown from './AppbarDropdown';
import AppbarMobileDropdown from './AppbarMobileDropdown';
import { useModal } from 'hooks/useModal';
import AboutModal from './about-modal/AboutModal';

type Props = {
  toggle: () => void;
};

const classes = {
  buttonNavLink: `text-gray-500 dark:text-gray-200 flex items-center
   hover:bg-primary-light hover:text-primary dark:hover:bg-darkGray-light dark:hover:text-white
   px-3 py-2 rounded-md flex text-md md:text-sm font-medium no-underline 
  `,
};
function Appbar(props: Props) {
  const { toggle } = props;
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const { toggle: toggleAboutModal, modalOpen } = useModal({ initialMode: true });

  const handleToggleMobileMenu = () => {
    setToggleMobileMenu(!toggleMobileMenu);
  };

  return (
    <Fragment>
      {modalOpen && <AboutModal isOpen={modalOpen} toggle={toggleAboutModal} />}

      <nav className="bg-white dark:bg-darkGray border-b border-gray-100 dark:border-darkGray-light">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-4 ">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img className="h-10 w-10" src={Logo} alt="Logo" />
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center space-x-4">
                  <SubjectSearchButton toggle={toggle} />
                  <button onClick={toggleAboutModal} className={classes.buttonNavLink}>
                    <div className="w-5 h-5 mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    <span>Acerca de cronun</span>
                  </button>
                </div>
              </div>

              <div className="flex md:hidden">
                <SubjectSearchButton toggle={toggle} />
              </div>
            </div>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <AppbarDropdown />
              </div>
            </div>

            {/* Hamburger (Mobile-onky) */}
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={handleToggleMobileMenu}
                className="bg-white dark:bg-darkGray inline-flex items-center justify-center p-2 rounded-md text-gray-600 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-300 focus:ring-white"
              >
                <span className="sr-only">Open main menu</span>

                <svg
                  className={`${toggleMobileMenu ? `hidden` : `block`} h-6 w-6 `}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>

                <svg
                  className={`${toggleMobileMenu ? `block` : `hidden`} h-6 w-6 `}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        <div className={`${toggleMobileMenu ? `block` : `hidden`} md:hidden`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <button onClick={toggleAboutModal} className={classes.buttonNavLink}>
              <div className="w-5 h-5 mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <span>Acerca de cronun</span>
            </button>
          </div>
          <AppbarMobileDropdown />
        </div>
      </nav>
    </Fragment>
  );
}

export default Appbar;
