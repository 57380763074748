import React, { useCallback } from 'react';

import { Group } from 'library/app-types';

import GroupListItem from './CurrentGroupListItem';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { removeSubject } from 'redux/slices/subjectsSlice';
import EmptyGroups from './EmptyGroups';
import { useAppPreferences } from 'hooks/useAppPreferences';
import { removeAllSchedules, setMatrixTemplate } from 'redux/slices/schedulesSlice';
import { generateEmptyMatrix } from 'library/generateEmptyMatrix';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

type Props = {
  /** Lista de materias agregadas actualmente por el usuario */
  currentSchedule: Group[];
  /** Determina si puedes o no eliminar materias */
  readOnly: boolean;
};

const EMPTY_MATRIX = generateEmptyMatrix();

function CurrentSubjectsList(props: Props) {
  const { currentSchedule, readOnly } = props;
  const dispatch = useAppDispatch();
  const currentSubjects = useSelector((state: RootState) => state.subjects.currentSubjects);
  const schedules = useSelector((state: RootState) => state.schedules.schedules);
  const [preferences] = useAppPreferences();

  const onRemoveSubject = useCallback(
    (subjectId: string) => {
      if (schedules.length > 0) {
        dispatch(
          removeSubject({
            id: subjectId,
          }),
        );
        if (currentSubjects.length === 1) {
          document
            .querySelectorAll('.ui-selected')
            .forEach((elem) => elem.classList.remove('ui-selected'));
          dispatch(setMatrixTemplate(EMPTY_MATRIX));
          dispatch(removeAllSchedules());
        }
      }
    },
    [dispatch, currentSubjects.length, schedules.length],
  );

  return currentSchedule.length > 0 ? (
    <ul className="list-none">
      {currentSchedule.map((group) => {
        return (
          <GroupListItem
            key={group.id}
            readOnly={readOnly}
            onRemoveSubject={onRemoveSubject}
            viewMode={preferences.viewMode}
            group={group}
          />
        );
      })}
    </ul>
  ) : (
    <EmptyGroups />
  );
}

export default CurrentSubjectsList;
