import clsx from 'clsx';
import useDarkMode from 'hooks/useDarkMode';
import React, { useState } from 'react';

const switchClasses = {
  container: `w-16 mr-4 h-8 rounded-full flex-shrink-0 p-1 duration-300 ease-in-out transition-colors`,
  switch: `bg-white w-6 h-6 rounded-full shadow-md transform duration-300 ease-in-out`,
};

type Props = {
  onToggle: () => void;
};
function Switch(props: Props) {
  const [theme, setMode] = useDarkMode();
  const [currentMode, setCurrentMode] = useState(localStorage.getItem('theme') === 'dark' || false);

  const handleToggle = () => {
    if (theme === 'dark') {
      setCurrentMode(false);
      setMode('light');
    } else {
      setCurrentMode(true);
      setMode('dark');
    }
  };

  return (
    // Switch container
    <div
      onClick={handleToggle}
      className={clsx([switchClasses.container, currentMode ? `bg-primary` : `bg-gray-300`])}
    >
      {/* Switch */}
      <div className={clsx([switchClasses.switch, currentMode ? `translate-x-8` : null])}></div>
    </div>
  );
}

export default React.memo(Switch);
