export const AUTH_TOKEN_COOKIE = 'auth-token';
export const USER_LOCAL_STORAGE = 'user-data';
export const DEFAULT_EMPTY_SCHEDULE: [] = [];
export const NO_SCHEDULE_GENERATED = {
  title: 'Ningún horario generado',
  description:
    'No pudimos generar un horario con los filtros establecidos. Prueba con otros filtros y horas bloqueadas.',
};

/** Data headers de una matriz de horarios */
export const dataHeaders = {
  headers: [
    { name: 'Hora' },
    { name: 'Lunes' },
    { name: 'Martes' },
    { name: 'Miercoles' },
    { name: 'Jueves' },
    { name: 'Viernes' },
    { name: 'Sábado' },
  ],
};
/** Columnas correspondientes a una matriz de horario */
export const tableRows = {
  data: [
    { id: 0, hour: '6:30 - 7:30' },
    { id: 1, hour: '7:30 - 8:30' },
    { id: 2, hour: '8:30 - 9:30' },
    { id: 3, hour: '9:30 - 10:30' },
    { id: 4, hour: '10:30 - 11:30' },
    { id: 5, hour: '11:30 - 12:30' },
    { id: 6, hour: '12:30 - 13:30' },
    { id: 7, hour: '13:30 - 14:30' },
    { id: 8, hour: '14:30 - 15:30' },
    { id: 9, hour: '15:30 - 16:30' },
    { id: 10, hour: '16:30 - 17:30' },
    { id: 11, hour: '17:30 - 18:30' },
    { id: 12, hour: '18:30 - 19:30' },
    { id: 13, hour: '19:30 - 20:30' },
    { id: 14, hour: '20:30 - 21:30' },
  ],
};

export const scheduleHours = [
  '6:30-7:30',
  '7:30-8:30',
  '8:30-9:30',
  '9:30-10:30',
  '10:30-11:30',
  '11:30-12:30',
  '12:30-13:30',
  '13:30-14:30',
  '14:30-15:30',
  '15:30-16:30',
  '16:30-17:30',
  '17:30-18:30',
  '18:30-19:30',
  '19:30-20:30',
  '20:30-21:30',
];
