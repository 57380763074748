import clsx from 'clsx';
import React from 'react';

type Props = React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> & {
  /** Estado de si esta seleccionada o no */
  selected: boolean;
  /** Elemento a renderizar */
  children: React.ReactNode;
};
const classes = {
  itemBase: `border-b px-4 py-4 text-sm transition-colors cursor-pointer `,
  selectedItem: `bg-primary text-white border-primary-dark`,
  notSelected: `bg-white dark:text-gray-100 dark:bg-darkGray-dark hover:bg-gray-100 dark:hover:bg-darkGray-light dark:border-darkGray-light border-gray-200`,
};
function ListItem(props: Props): React.ReactElement<Props> {
  return (
    <li
      className={clsx([
        classes.itemBase,
        props.selected ? classes.selectedItem : classes.notSelected,
      ])}
      {...props}
    >
      {props.children}
    </li>
  );
}

export default ListItem;
