import React, { Fragment } from 'react';

import clsx from 'clsx';

import { Subject } from 'library/app-types';
import CloseIcon from 'library/icons/CloseIcon';
import DocumentAddIcon from 'library/icons/DocumentAddIcon';

import { searchItemClasses } from './SubjectSearchItem';

type Props = {
  subject: Subject;
  onRemoveSubject: (subjectId: string) => void;
};
function UserSubjectListItem(props: Props) {
  const { subject, onRemoveSubject } = props;
  const { name, mat, id } = subject;

  const handleRemoveSubject = () => {
    onRemoveSubject(id);
  };

  return (
    <Fragment>
      <li className={searchItemClasses.wrapper}>
        <div className={clsx([searchItemClasses.container])}>
          <div className={searchItemClasses.row}>
            <div className={searchItemClasses.icon}>
              <DocumentAddIcon />
            </div>
            <div className={searchItemClasses.col}>
              <span className={searchItemClasses.subjectMat}>{mat}</span>
              <span className={searchItemClasses.subjectName}>{name}</span>
            </div>
            <div
              title="Eliminar materia"
              className="group-hover:text-primary dark:text-gray-50 group-hover:bg-primary-light rounded-full py-2 px-2 "
              onClick={handleRemoveSubject}
            >
              <CloseIcon />
            </div>
          </div>
        </div>
      </li>
    </Fragment>
  );
}
export default UserSubjectListItem;
