import React from 'react';

type Props = {
  /** Si esta desactivado, no renderiza un label a la derecha */
  renderRightLabel?: boolean;
  /** Texto a desplegar en el boton */
  title: string;
  /** Label title */
  label?: string | number;
  /** Icon to render */
  icon: React.ReactNode;
  /** Onclick Handler */
  onClick?: () => void;
  disabled?: boolean;
};
function ToolbarButton(props: Props) {
  const { renderRightLabel, title, label, icon, onClick, disabled } = props;
  return (
    <button
      type="button"
      className="flex justify-between items-center text-xs mx-2 group dark:bg-darkGray-light dark:hover:bg-darkGray-light dark:hover:text-whiteLg dark:text-gray-50 px-2 py-1 rounded-lg shadow-tag active:shadow-tag-pressed hover:bg-gray-100 focus:outline-none"
      onClick={onClick}
      disabled={disabled}
    >
      <div
        title="Quitar materia"
        className="w-5 h-5 text-gray-600 group-hover:text-gray-900 dark:group-hover:text-gray-50  dark:text-whiteLg-light mr-2 ml-2"
      >
        {icon}
      </div>
      <span className="mr-2">{title}</span>
      {renderRightLabel && <span className="border-l px-2 py-1">{label}</span>}
    </button>
  );
}
export default React.memo(ToolbarButton);
