import ListItem from 'components/list/ListItem';
import { UserSchedule } from 'library/app-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

type Props = {
  /**Schedule a mostrar */
  userSchedule: UserSchedule;
  /** Evento para mostrar el horario */
  onShowListItem: (currentSheduleMetaData: UserSchedule) => void;
};
function ScheduleFavsListItem(props: Props) {
  const { userSchedule, onShowListItem } = props;
  const { name, id } = userSchedule;

  const { currentUserSchedule } = useSelector((state: RootState) => state.schedules);

  const onShowUserSchedule = () => {
    onShowListItem(userSchedule);
  };
  return (
    <ListItem onClick={onShowUserSchedule} selected={currentUserSchedule?.id === id}>
      {name}
    </ListItem>
  );
}

export default React.memo(ScheduleFavsListItem);
