import React, { Fragment, useRef, useCallback, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { useModal } from 'hooks/useModal';
import { useAppDispatch } from 'hooks/useAppDispatch';

import { setCurrentPage, setMatrixTemplate } from 'redux/slices/schedulesSlice';

import Table from 'components/table/Table';

import BookmarkIcon from 'library/icons/BookmarkIcon';
import DeleteIcon from 'library/icons/DeleteIcon';
import { generateEmptyMatrix } from 'library/generateEmptyMatrix';

import ToolbarButton from './ToolbarButton';
import SchedulePaginator from './SchedulePaginator';
import DownloadScheduleDropdown from './DownloadScheduleDropdown';
import CurrentSubjectsModal from './current-groups-modal/CurrentGroupsModal';
import { useAppPreferences } from 'hooks/useAppPreferences';
import SaveScheduleModal from '../SaveScheduleModal';
import { SelectableGroup } from 'react-selectable-fast';
import Favorite from 'library/icons/Favorite';

const EMPTY_MATRIX = generateEmptyMatrix();

function ScheduleGenerate() {
  const dispatch = useAppDispatch();
  const [preferences] = useAppPreferences();
  const selectableRef = useRef<SelectableGroup>();
  const { toggle, modalOpen } = useModal({ initialMode: false });
  const { toggle: togglePinSchedule, modalOpen: modalPinOpen } = useModal({ initialMode: false });
  const { currentSchedule, currentPage, schedules, matrixTemplate } = useSelector(
    (state: RootState) => state.schedules,
  );

  /**
   * Funcion para moverse entre los horarios
   * @param page Pagina  la cual debe moverse el usuario
   */
  const handleMoveToPage = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  /**
   * Correr este efecto cuando el componente se monte para re-establecer las casillas que estaban bloqueadas
   */
  useEffect(() => {
    let matrix = Array.from(matrixTemplate, (arr) => [...arr]);
    const selectedClasses = ['ui-selected', 'bg-secondary', 'hover:bg-secondary'];
    if (matrix.length > 0) {
      for (var i = 0; i < matrix.length; i++) {
        const element = matrix[i];
        for (var j = 0; j < element.length; j++) {
          if (element[j] === 'blocked') {
            // Aquí obtengo la posición en la table HTML que estuvo marcada
            const tdBlocked = document.getElementById(`${i},${j}`);
            tdBlocked?.classList.add(...selectedClasses);
          }
        }
      }
    }
  }, [matrixTemplate]);
  /**
   * Limpia las casillas marcadas por el usuario como 'bloquedas'
   */
  const cleanFilters = useCallback(() => {
    const classesToRemove = ['ui-selected', 'bg-secondary', 'hover:bg-secondary'];
    selectableRef.current?.clearSelection();
    document
      .querySelectorAll('.ui-selected')
      .forEach((elem) => elem.classList.remove(...classesToRemove));

    dispatch(setMatrixTemplate(EMPTY_MATRIX));
  }, [dispatch]);

  /**
   * Cuando se detenga de seleccionar en la matriz, debería disparar el efecto del worker de nuevo.
   */
  const onStopSelecting = useCallback(() => {
    let x, y, scheduleMatrixRepresentation; // Posicion de la columna seleccionada
    scheduleMatrixRepresentation = generateEmptyMatrix(); // Genero una matriz vacía
    const selectedItems = document.querySelectorAll('.ui-selected');
    const selectedCells = Array.from(selectedItems.entries());

    for (let i = 0; i < selectedCells.length; i++) {
      let element: any = selectedCells[i][1];
      x = element.cellIndex;
      y = element.parentNode.rowIndex;
      if (scheduleMatrixRepresentation[x - 1][y - 1] === 'blocked') {
        scheduleMatrixRepresentation[x - 1][y - 1] = null;
      } else {
        scheduleMatrixRepresentation[x - 1][y - 1] = 'blocked';
      }
    }
    dispatch(setMatrixTemplate(scheduleMatrixRepresentation));
  }, [dispatch]);

  return (
    <Fragment>
      {modalOpen && (
        <CurrentSubjectsModal
          isOpen={modalOpen}
          toggle={toggle}
          currentSchedule={currentSchedule}
        />
      )}
      {modalPinOpen && <SaveScheduleModal isOpen={modalPinOpen} toggle={togglePinSchedule} />}

      <div className="hidden md:block pb-2">
        <div className="flex flex-row-reverse">
          {/* Render group of buttons */}
          <DownloadScheduleDropdown currentSchedule={currentSchedule} />
          <SchedulePaginator
            currentPage={currentPage}
            totalPages={schedules.length}
            moveToPage={handleMoveToPage}
          />
          {preferences.viewMode === 0 ? (
            <ToolbarButton
              onClick={toggle}
              icon={<BookmarkIcon />}
              label={currentSchedule.length}
              title="Materias"
              renderRightLabel={true}
            />
          ) : null}
          <ToolbarButton onClick={togglePinSchedule} icon={<Favorite />} title="Añadir favoritos" disabled/>
          <ToolbarButton onClick={cleanFilters} icon={<DeleteIcon />} title="Limpiar casillas" />
        </div>
      </div>
      <div className="px-4 block md:hidden mb-2">
        <span className=" text-xs text-gray-600 dark:text-whiteLg-light ">
          <em>
            Nota: Haz swipe sobre los dias y las horas para desplazarte, así no marcarás las
            casillas
          </em>
        </span>
      </div>

      <div className="px-4 md:px-0 pb-2">
        <Table
          tableRef={selectableRef}
          currentSchedule={currentSchedule}
          onStopSelecting={onStopSelecting}
        />
        <div className="pt-2 md:hidden">
          <SchedulePaginator
            currentPage={currentPage}
            totalPages={schedules.length}
            moveToPage={handleMoveToPage}
          />
        </div>
      </div>

      <div className="flex flex-col px-4 md:hidden">
        <div className="flex flex-col sm:flex-row md:items-center py-2 justify-between">
          <div className="flex-col  sm:flex-row md:items-center  justify-between ">
            <h3 className="font-medium text-sm text-gray-900 dark:text-whiteLg-high">Descargar</h3>
            <p className="text-gray-700 dark:text-whiteLg text-sm mt-2 mb-2 sm:mt-0 sm:mb-0">
              Descarga este horario en PNG, CSV o PDF <strong>(Recomendado)</strong>
            </p>
          </div>
          <div className="flex flex-row-reverse sm:flex-row ">
            <DownloadScheduleDropdown currentSchedule={currentSchedule} />
          </div>
        </div>
      </div>
      <div className="flex px-4 md:hidden">
        <ToolbarButton onClick={togglePinSchedule} icon={<Favorite />} title="Añadir favoritos" disabled/>
        <ToolbarButton onClick={toggle} icon={<DeleteIcon />} title="Limpiar " />
      </div>
      {preferences.viewMode === 0 ? (
        <div className="flex px-4 flex-col mt-4 md:hidden">
          <button
            onClick={toggle}
            className="shadow-tag w-full flex items-center text-sm py-2 px-4 mb-2 bg-white dark:bg-darkGray rounded-lg hover:bg-gray-100 dark:hover:bg-darkGray-light"
          >
            <div className="w-4 h-4 flex-shrink-0 dark:text-gray-50 text-gray-900">
              <Favorite />
            </div>
            <span className="flex-grow text-gray-900 dark:text-gray-50">Ver materias</span>
          </button>
        </div>
      ) : null}
    </Fragment>
  );
}

export default React.memo(ScheduleGenerate);
