import { useQuery } from '@apollo/client';
import { captureException } from '@sentry/react';
import List from 'components/list/List';
import getUserSchedules from 'graphql/queries/getUserSchedules';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useAuth } from 'hooks/useAuth';
import { UserSchedule, UserSchedulesMutationData } from 'library/app-types';
import { saveUserSchedules } from 'library/helpers';
import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { setCurrentUserSchedule } from 'redux/slices/schedulesSlice';
import { RootState } from 'redux/store';
import NoSubjectToFilter from '../schedule-filters/NoSubjectToFilter';
import ScheduleFavsListItem from './ScheduleFavsListItem';

type Props = {
  searchQuery: string;
};

function ScheduleFavsList(props: Props) {
  const { searchQuery } = props;
  const auth = useAuth();

  const { data, loading, error, networkStatus } = useQuery<UserSchedulesMutationData>(
    getUserSchedules,
    {
      notifyOnNetworkStatusChange: true,
    },
  );
  const dispatch = useAppDispatch();
  const { userSchedulesMetadata } = useSelector((state: RootState) => state.schedules);
  /**
   * Evento al darle click al horario para mostrarlo en la matriz
   */
  const onShowCurrentUserSchedule = useCallback(
    (scheduleMetaData: UserSchedule) => {
      dispatch(setCurrentUserSchedule(scheduleMetaData));
    },
    [dispatch],
  );

  /**
   * En el onMount del componente guardo los horarios parseados y coloco siempre el primero como el seleccionado.
   */
  useEffect(() => {
    if (data) {
      saveUserSchedules(data?.userSchedules, dispatch);
    }
  }, [data, dispatch]);

  /**
   * Calcula los indices de los horarios marcados, tal que pueda recorrerlos en el objeto que está en redux
   */
  const filteredSchedulesMetaData = useMemo(() => {
    const filteredSchedulesMetaData = [] as UserSchedule[];
    userSchedulesMetadata.forEach(({ name }, index) => {
      if (searchQuery.length === 0 || name.toLowerCase().includes(searchQuery.toLowerCase())) {
        filteredSchedulesMetaData.push(userSchedulesMetadata[index]);
      }
    });
    return filteredSchedulesMetaData;
  }, [searchQuery, userSchedulesMetadata]);

  if (loading || networkStatus === 4) {
    return (
      <span className="text-xs dark:text-gray-50 text-gray-600">
        <em>Cargando horarios...</em>
      </span>
    );
  }

  if (error) {
    captureException(error, {
      user: {
        id: auth?.sessionUser.id,
      },
    });

    return (
      <span className="text-xs dark:text-gray-50 text-gray-600">
        Ocurrió un error cargando los horarios, intenta recargando la página
      </span>
    );
  }

  if (data?.userSchedules.length === 0) {
    return (
      <Fragment>
        <span className="text-xs dark:text-gray-50 text-gray-600">
          <em>No tienes horarios guardados aún</em>
        </span>
        <NoSubjectToFilter />
      </Fragment>
    );
  }

  return (
    <List>
      {filteredSchedulesMetaData.map((userScheduleMetaData) => {
        return (
          <ScheduleFavsListItem
            key={userScheduleMetaData.id}
            onShowListItem={onShowCurrentUserSchedule}
            userSchedule={userScheduleMetaData}
          />
        );
      })}
    </List>
  );
}

export default ScheduleFavsList;
