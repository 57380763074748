import Checkbox from 'components/Checkbox';
import React from 'react';

type Props = {
  /** Titulo de la configuración */
  settingName: string;
  /** Descripción de la configuración */
  description?: React.ReactNode | string;
  /** Funcion a ejecutar en el onChange del checkbox */
  onSettingToggled: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** Estado de activado/desactivado de la configuración */
  isToggled: boolean;
};

function SettingBox(props: Props) {
  const { settingName, description, onSettingToggled, isToggled } = props;

  return (
    <div className="p-5 bg-gray-50 dark:bg-darkGray dark:border-darkGray-light flex rounded-lg border border-gray-200 mt-2 mb-2">
      <div className="flex-shrink-0">
        <Checkbox onChange={onSettingToggled} checked={isToggled} />
      </div>
      <section className="flex flex-col">
        <h1 className="font-semibold text-sm text-gray-900 dark:text-whiteLg-high">
          {settingName}
        </h1>
        <p className="text-sm text-gray-800 dark:text-whiteLg">{description}</p>
      </section>
    </div>
  );
}
export default SettingBox;
