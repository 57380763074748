import ListItem from 'components/list/ListItem';
import { Subject } from 'library/app-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

type Props = Subject & {
  /** Handler al seleccionar una materia */
  onSelectSubject: (subject: Subject) => void;
};

function SubjectNavigationListItem(props: Props) {
  const { onSelectSubject, ...subject } = props;
  const currentSelectedSubject = useSelector(
    (state: RootState) => state.subjects.currentSelectedSubject,
  );

  const handleSelectSubject = () => {
    onSelectSubject(subject);
  };

  return (
    <ListItem onClick={handleSelectSubject} selected={currentSelectedSubject?.id === subject.id}>
      {subject.name}
    </ListItem>
  );
}
export default React.memo(SubjectNavigationListItem);
