import TwoSideLayout from 'layout/TwoSideLayout';
import React from 'react';
import ScheduleFavsContent from './ScheduleFavsContent';
import ScheduleFavsSidenav from './ScheduleFavsSidenav';

function ScheduleFavs() {
  return <TwoSideLayout leftSide={<ScheduleFavsSidenav />} rightRide={<ScheduleFavsContent />} />;
}

export default ScheduleFavs;
