import Checkbox from 'components/Checkbox';
import { Group } from 'library/app-types';
import React from 'react';
import SubjectProfessorGroupSchedule from './SubjectProfessorGroupSchedule';

type Props = {
  /** Grupo a renderizar */
  group: Group;
  /** Evento para bloquear el grupo */
  onBlockGroup: (isBlocked: boolean, groupNrc: string) => void;
};
function SubjectProfessorGroupItem(props: Props) {
  const { group, onBlockGroup } = props;

  const onBlockProfessorGroup = (e: React.ChangeEvent<HTMLInputElement>) => {
    onBlockGroup(e.target.checked, group.nrc);
  };
  return (
    <li className="flex px-4 py-2 items-center border-b border-gray-200 dark:border-darkGray-light ">
      <Checkbox onChange={onBlockProfessorGroup} checked={group.blocked} />
      <SubjectProfessorGroupSchedule groupNrc={group.nrc} schedules={props.group.schedule} />
    </li>
  );
}
export default SubjectProfessorGroupItem;
