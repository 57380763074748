import React from 'react';
type Props = {
  children: React.ReactNode;
};
function MinimalLayout(props: Props) {
  return (
    <main>
      <div className="max-w-7xl mx-auto py-2 sm:px-6 lg:px-8 dark:bg-darkGray-dark">
        <div className="px-4 pt-2 pb-6 sm:px-0">{props.children}</div>
      </div>
    </main>
  );
}

export default MinimalLayout;
