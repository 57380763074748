import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Group,
  GroupStatus,
  RemoveSubjectPayload,
  SaveGroupStatusPayload,
  Subject,
} from 'library/app-types';

type subjectState = {
  currentSubjects: Subject[];
  groupStatuses: GroupStatus[];
  currentSelectedSubject: Subject | null;
};

const initialState = {
  currentSubjects: [],
  groupStatuses: [],
  currentSelectedSubject: null,
} as subjectState;

const subjectSlice = createSlice({
  name: 'subjects',
  initialState,
  reducers: {
    addSubject(state, action: PayloadAction<Subject>) {
      state.currentSubjects.push(action.payload);
    },
    removeSubject(state, action: PayloadAction<RemoveSubjectPayload>) {
      const newSubjects = state.currentSubjects.filter(
        (subject) => subject.id !== action.payload.id,
      );
      const newGroupStatuses = state.groupStatuses.filter(({ nrc }) => {
        return newSubjects.some((subject) => subject.groups.some((group) => group.nrc === nrc));
      });
      //  state.currentGroups.filter(
      //   group => group.subject.id !== action.subjectId
      // )

      if (state.currentSelectedSubject?.id === action.payload.id) {
        if (newSubjects.length > 0) {
          state.currentSelectedSubject = newSubjects[0];
        } else {
          state.currentSelectedSubject = null;
        }
      }

      state.groupStatuses = newGroupStatuses;
      state.currentSubjects = newSubjects;
    },
    removeAllSubjects(state) {
      state.currentSubjects = [];
    },
    saveGroupStatus(state, action: PayloadAction<SaveGroupStatusPayload>) {
      const { blocked, groupsNrc } = action.payload;
      const newGroupStatuses: GroupStatus[] = [];
      groupsNrc.forEach((nrc) => {
        newGroupStatuses.push({
          nrc,
          blocked,
        });
      });
      state.groupStatuses.forEach(({ nrc, blocked }) => {
        if (!newGroupStatuses.some((group) => group.nrc === nrc)) {
          newGroupStatuses.push({
            nrc,
            blocked,
          });
        }
      });
      state.groupStatuses = newGroupStatuses;
    },
    updateGroupStatuses(state) {
      const newSubjects = [...state.currentSubjects];
      let selectedGroup: Group;
      state.groupStatuses.forEach(({ blocked, nrc }) => {
        newSubjects.find((subject) =>
          subject.groups.some((group) => {
            selectedGroup = group;
            return group.nrc === nrc;
          }),
        );
        if (selectedGroup) {
          selectedGroup.blocked = blocked;
        }
      });
      state.currentSubjects = [...newSubjects];
    },
    removeAllGroupsStatuses(state) {
      state.groupStatuses = [];
    },
    selectCurrentSubject(state, action) {
      state.currentSelectedSubject = action.payload;
    },
  },
});

const { actions, reducer } = subjectSlice;

export const {
  addSubject,
  removeSubject,
  removeAllGroupsStatuses,
  removeAllSubjects,
  saveGroupStatus,
  updateGroupStatuses,
  selectCurrentSubject,
} = actions;

export default reducer;
