type Props = {
  /** Nombre necesario para tomar la primera letra */
  name: string;
  /** Fondo de color del avatar */
  bgColor?: string;
  /** Color de la fuente  */
  font: string;
};
const avatarClasses = {
  container: `w-9 h-9 relative `,
  circle: `group w-full h-full rounded-full overflow-hidden text-center bg-gray-600 cursor-pointer flex items-center justify-center`,
  abbr: `font-medium align-middle text-white border-none no-underline focus:no-underline uppercase   `,
};
export default function Avatar(props: Props) {
  const { name, bgColor, font } = props;

  return (
    <div className={avatarClasses.container}>
      <div style={{ backgroundColor: bgColor }} className={avatarClasses.circle}>
        <abbr style={{ color: font }} title={name} className={avatarClasses.abbr}>
          {name.substring(0, 2)}
        </abbr>
      </div>
    </div>
  );
}
