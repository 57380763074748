import React from 'react';
import loading from 'assets/images/loading.gif';

export default function LoadingBackdrop() {
  return (
    <div className="rounded-3xl flex justify-center items-center  inset-0 absolute w-full h-full z-40">
      <div className="relative flex justify-center items-center z-50 w-full h-full bg-lightTransparent dark:bg-darkTransparent rounded-3xl">
        <img className="w-20 h-20 opacity-100" src={loading} alt="Loading..." />
      </div>
    </div>
  );
}
