import algoliaLogo from 'assets/images/algolia_logo.png';
import LoadingBackdrop from 'components/LoadingBackdrop';
import Modal from 'components/Modal';
import { ModalProps, SubjectHit } from 'library/app-types';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import SearchTabs from './SearchTabs';
import SchedulesSearchBar from './SubjectSearchBar';
import SubjectSearchList from './SubjectSearchList';
import SubjectSearchListLoading from './SubjectSearchListLoading';
import NotFound from './SubjectSearchNotFound';
import UserSubjectList from './UserSubjectList';

const modalClasses = {
  panelContainer: `relative w-full md:w-8/12 my-6 mx-auto max-w-5xl  md:max-w-3xl`,
  panelContent: `border-0 divide-y divide-gray-200 dark:divide-darkGray-light 
  rounded-3xl shadow-lg relative flex flex-col w-full bg-white dark:bg-darkGray outline-none focus:outline-none `,
};

function SubjectSearch(props: ModalProps): React.ReactElement<ModalProps> {
  const { isOpen, toggle } = props;
  const [findedSubjects, setFindedSubjects] = useState<SubjectHit[] | null>(null);
  const [lastResults, setLastResults] = useState<string | null>(null);
  const [currentTab, setCurrentTab] = useState(0);
  const currentUserSubjects = useSelector((state: RootState) => state.subjects.currentSubjects);
  const isLoading = useSelector((state: RootState) => state.app.isLoadingNewSubject);

  const setSubjectHitsHandler = useCallback((findedHits: SubjectHit[]) => {
    setFindedSubjects(findedHits);
  }, []);

  /** Función para colocar cual fué el último termino de búsqueda del usuario */
  const setLastSubjectSearchTerm = useCallback((lastSearchTerm: string) => {
    setLastResults(lastSearchTerm);
  }, []);

  /** Manejar el cambio de tabs (Búsqueda y Materias agregadas) */
  const handleTabChange = useCallback((tabIndex: number) => {
    setCurrentTab(tabIndex);
  }, []);

  /** Retornar la lista de hits encontrados */
  const subjectSearchList = useMemo(() => {
    return <SubjectSearchList subjectHits={findedSubjects} currentSubjects={currentUserSubjects} />;
  }, [findedSubjects, currentUserSubjects]);

  /** Retornar la lista de materias agregadas actualmente */
  const currentAddedSubjectsList = useMemo(() => {
    return <UserSubjectList currentSubjects={currentUserSubjects} />;
  }, [currentUserSubjects]);

  return (
    <Modal onClose={toggle} isOpen={isOpen}>
      {/* Panel */}
      <div className={modalClasses.panelContainer}>
        <div className={modalClasses.panelContent}>
          {isLoading && <LoadingBackdrop />}
          <section className="px-8 py-5 ">
            <SchedulesSearchBar
              onLoadLastResult={setLastSubjectSearchTerm}
              onLoadHits={setSubjectHitsHandler}
              onClose={toggle}
            />
          </section>
          {/* <div className="divide-y dark:divide-darkGray-light w-full"></div> */}
          <SearchTabs
            currentSubjects={currentUserSubjects}
            tabIndex={currentTab}
            onTabSelected={handleTabChange}
          />
          {currentTab === 0 ? (
            <section className="px-8 pt-2 pb-8 overflow-y-auto max-h-480 relative">
              {findedSubjects ? (
                <Fragment>
                  <h3 className="text-sm font-medium text-gray-700 dark:text-whiteLg">
                    Total encontradas: {findedSubjects.length}
                  </h3>
                  {findedSubjects.length > 0
                    ? subjectSearchList
                    : lastResults && <NotFound searchTerm={lastResults} />}
                </Fragment>
              ) : (
                <Fragment>
                  <SubjectSearchListLoading />
                </Fragment>
              )}
            </section>
          ) : (
            <section className="max-h-480 overflow-y-auto">{currentAddedSubjectsList}</section>
          )}

          <hr className="divide divide-x-1 divide-gray-200 dark:divide-darkGray-light w-full"></hr>
          <section className="px-8 py-8 flex flex-row-reverse">
            <div className="flex items-center justify-center ">
              <span className="text-gray-900 dark:text-whiteLg text-xs pr-2">Search By</span>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.algolia.com/ref/docsearch/?utm_source=docsearch&utm_medium=link&utm_term=footer&utm_campaign="
                className="no-underline w-16"
              >
                <img className="w-full" src={algoliaLogo} alt="Algolia Logo" />
              </a>
            </div>
          </section>
        </div>
      </div>
    </Modal>
  );
}

export default SubjectSearch;
