import CheckIcon from 'library/icons/CheckIcon';
import React from 'react';

function EmptyGroups() {
  return (
    <div className="h-48">
      <p className="pb-8 pt-5 text-gray-500 dark:text-whiteLg-high">No hay materias agregadas</p>
      <div className="flex flex-col">
        <p className="border-b border-gray-200 dark:border-darkGray-light uppercase tracking-wid text-sm dark:text-whiteLg text-gray-700">
          Debes agregar materias para poder proceder:
        </p>
        <ol>
          <li className="text-gray-800 dark:text-whiteLg py-4 inline-flex items-center">
            <div className="mr-4 w-5 h-5">
              <CheckIcon />
            </div>
            Escribe el nombre de la materia que quieres buscar
          </li>
        </ol>
      </div>
    </div>
  );
}

export default EmptyGroups;
