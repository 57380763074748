import React from 'react';

const classes = {
  col: `flex flex-col`,
  container: `flex -space-x-2 overflow-hidden px-2 py-2`,
  inlineImage: `inline-block h-10 w-10 rounded-full ring-2 ring-white`,
  userLabels: `text-xs text-gray-500 dark:text-whiteLg-light`,
};

export default function Contributors() {
  return (
    <div className={classes.col}>
      <div className={classes.container}>
        <img
          title="Gustavo Guerrero"
          className={classes.inlineImage}
          src="https://avatars3.githubusercontent.com/u/22797836?s=400&u=81ce7bbfdffd14f6c63b08564dd9fd04bd889f1b&v=4"
          alt=""
        />
        {/* <img
          className={classes.inlineImage}
          src="https://instagram.fbaq2-2.fna.fbcdn.net/v/t51.2885-19/s150x150/119065211_1246914648995546_6291871125963190799_n.jpg?_nc_ht=instagram.fbaq2-2.fna.fbcdn.net&_nc_ohc=k8Q7REJhem4AX8rpqkw&tp=1&oh=270d8c6133b56ae15db10473b6135f47&oe=602501BB"
          alt="Alvaro Anaya"
        /> */}
        <img
          title="Juan Estrada"
          className={classes.inlineImage}
          src="https://avatars3.githubusercontent.com/u/26317261?s=400&u=747f005235d9e394d689e31b9f6bd6b11532a996&v=4"
          alt=""
        />
        <img
          title="William Cadenas"
          className={classes.inlineImage}
          src="https://avatars3.githubusercontent.com/u/20405614?s=400&u=7d1e76eaef8ab805a786d0ef8a9ecb6f7e1d6628&v=4"
          alt="William Cadenas"
        />
      </div>

      <span className={classes.userLabels}>
        Gustavo Guerrero, Alvaro Anaya, Juan Estrada, William Cadenas
      </span>
    </div>
  );
}
