import React, { Fragment } from 'react';
type Props = {
  /** Componente que renderizará un Appbar */
  appBar: React.ReactNode;
  /** SubAppbar, componente que se ubica abajo del appbar */
  subBar: React.ReactNode;
  /** Main del componente */
  body: React.ReactNode;
};
function DefaultLayout(props: Props) {
  const { appBar, subBar, body } = props;
  return (
    <Fragment>
      {appBar}
      {subBar}
      {body}
    </Fragment>
  );
}

export default React.memo(DefaultLayout);
