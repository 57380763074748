import clsx from 'clsx';
import useRouter from 'hooks/useRouter';
import React, { useState } from 'react';

const dropdownClasses = {
  menu: `origin-top-right
   absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-darkGray 
   ring-1 ring-gray-200 dark:ring-darkGray-light`,
  menuItem: `block w-full text-left px-4 py-2 text-sm `,
};

// Andres..

function SubBarDropown() {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const { history, match, location } = useRouter();
  return (
    <div className="ml-3 absolute right-4 bottom-1 md:hidden z-50">
      <button
        className="shadow-sm border border-gray-200 dark:border-darkGray-light bg-white dark:bg-darkGray-light rounded-lg px-2 py-2 text-gray-900 dark:text-gray-50 focus:outline-none"
        onClick={() => setToggleDropdown((prevState) => !prevState)}
      >
        <div className="w-5 h-5 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
            />
          </svg>
        </div>
      </button>
      <div
        className={clsx([dropdownClasses.menu, !toggleDropdown ? `hidden` : null])}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="download-menu"
      >
        <button
          onClick={() => history.push(`${match.url}/favorites`)}
          className={clsx([
            dropdownClasses.menuItem,
            location.pathname === '/schedules/favorites'
              ? `bg-primary text-white`
              : `hover:bg-gray-100 dark:hover:bg-darkGray-light text-gray-700  dark:text-gray-50`,
          ])}
          role="menuitem"
          disabled
        >
          Favoritos
        </button>
        <button
          onClick={() => history.push(`${match.url}/settings`)}
          className={clsx([
            dropdownClasses.menuItem,
            location.pathname === '/schedules/settings'
              ? `bg-primary text-white`
              : `hover:bg-gray-100 dark:hover:bg-darkGray-light  text-gray-700 dark:text-gray-50`,
          ])}
          role="menuitem"
        >
          Configuraciones
        </button>
      </div>
    </div>
  );
}

export default SubBarDropown;
