import clsx from 'clsx';
import { Group } from 'library/app-types';
import CloseIcon from 'library/icons/CloseIcon';
import ProfessorsNames from 'pages/schedule/schedule-filters/subject-professor/ProfessorsNames';
import React, { useMemo } from 'react';
import TotalGroupCoincidence from './TotalGroupCoincidence';
// Group & viewMode, readOnly
type Props = {
  onRemoveSubject: (subjectId: string) => void;
  /** ViewMode actual */
  viewMode: number;
  /** readOnly si puede o no eliminar materia */
  readOnly: boolean;
  /** Grupo actual */
  group: Group;
};

const classes = {
  liItem: `block border-l border-gray-300 dark:border-darkGray-light px-4 py-1 bg-white dark:bg-darkGray mb-2 relative`,
  itemContainer: `flex items-center justify-between`,
  itemContent: `flex flex-col justify-start`,
  itemContentHeader: `flex flex-col md:flex-row md:items-center`,
  itemContentHeaderTitle: `font-medium mr-2 text-sm text-gray-800 dark:text-whiteLg-high`,
  itemScheduleType: `text-sm text-gray-700 dark:text-whiteLg`,
  itemProfessorNames: `text-gray-900 font-normal dark:text-whiteLg text-xs`,
  itemMetaData: `font-medium text-xs text-gray-700 dark:text-gray-200 pt-1`,
  itemActionButton: ` flex-shrink-0 text-gray-300 hover:bg-gray-100 dark:hover:bg-darkGray-light dark:hover:text-gray-50 hover:text-gray-600 w-8 h-8  flex items-center rounded-full justify-center`,
};

function CurrentSubjectsListItem(props: Props): React.ReactElement<Props> {
  const { onRemoveSubject, viewMode, readOnly, group } = props;

  const {
    id,
    nrc,
    professors,
    subject: { name },
    scheduleType,
    groupNumber,
    quota,
    total,
  } = group;

  const handleRemoveSubject = () => {
    onRemoveSubject(id);
  };

  const professorNameList = useMemo(() => {
    return professors.map(({ name }) => name);
  }, [professors]);

  return (
    <li className={clsx([classes.liItem])}>
      <div className={clsx([classes.itemContainer])}>
        <div className={clsx([classes.itemContent])}>
          <div className={clsx([classes.itemContentHeader])}>
            <h3 className={clsx([classes.itemContentHeaderTitle])}>{name}</h3>
            {viewMode === 0 && <em className={clsx([classes.itemScheduleType])}>{scheduleType}</em>}
          </div>
          <p className={clsx([classes.itemProfessorNames])}>
            <ProfessorsNames viewMode={viewMode} professors={professorNameList.join(',')} />
          </p>
          {viewMode === 0 ? (
            <span className={clsx([classes.itemMetaData])}>
              Grupo {groupNumber} | NRC: 
              <span className="text-secondary dark:text-secondary-darker">{nrc}</span> | Quedan{' '}
              {quota.free} cupos
            </span>
          ) : (
            <span className={clsx([classes.itemMetaData])}>
              <span className="text-secondary  dark:text-secondary-darker">{nrc}</span> |{' '}
              {quota.free} cupos | <em>{scheduleType}</em>
            </span>
          )}
          {total ? <TotalGroupCoincidence total={total} /> : null}
        </div>
        {!readOnly && (
          <button onClick={handleRemoveSubject} className={clsx([classes.itemActionButton])}>
            <CloseIcon width={20} height={20} />
          </button>
        )}
      </div>
    </li>
  );
}

export default CurrentSubjectsListItem;
