import List from 'components/list/List';
import ListItem from 'components/list/ListItem';
import React from 'react';

type Props = {
  /** Indice del tab seleccionado en la navegación del lado izquierdo */
  tabIndex: number;
  /** Evento para manejar el estado del tab */
  onTabChange: (tabIndex: number) => void;
};

function ScheduleSettingsSidenav(props: Props): React.ReactElement<Props> {
  const { tabIndex, onTabChange } = props;

  const handleTabChange = (tabIndex: number) => {
    onTabChange(tabIndex);
  };

  return (
    <div className="rounded-lg w-full px-4 md:px-0">
      <div className="py-4">
        <h1 className="text-xl text-gray-900 font-medium leading-4 ">Configuraciones</h1>
      </div>
      <List>
        <ListItem selected={tabIndex === 0} onClick={() => handleTabChange(0)}>
          Horarios
        </ListItem>
        <ListItem selected={tabIndex === 1} onClick={() => handleTabChange(1)}>
          Apariencia
        </ListItem>
      </List>
    </div>
  );
}

export default ScheduleSettingsSidenav;
