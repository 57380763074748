import { gql } from '@apollo/client';

/**
 * Quita un horario de la lista de horarios marcados
 */
export default gql`
  mutation RemoveScheduleMutation($id: ID!) {
    removeSchedule(id: $id)
  }
`;
