/**
 * (Documentación parcial) Reduce los horarios a la primera semana para estudiantes de medicina
 * @param _groups Grupos a agregar
 */
export const parseGroups = (_groups: any[]) => {
  const groups: any[] = [];
  // Reduce schedule to first week for medicine schedules
  let daysSet, parsedSchedule;
  for (let group of _groups) {
    if (group.schedule.length > 6) {
      daysSet = new Set();
      parsedSchedule = [];
      for (let schedule of group.schedule) {
        if (!daysSet.has(schedule.day)) {
          parsedSchedule.push(schedule);
          daysSet.add(schedule.day);
        }
        if (parsedSchedule.length === 6) {
          break;
        }
      }
      groups.push({
        ...group,
        blocked: false,
        schedule: parsedSchedule,
      });
    } else {
      let schedule = group.schedule;
      // Group schedule repeated time interval within the same day
      if (
        group.schedule.length > 1 &&
        group.schedule[0].day === group.schedule[1].day &&
        group.schedule[0].time.start === group.schedule[1].time.start &&
        group.schedule[0].time.end === group.schedule[1].time.end
      ) {
        schedule.splice(0, 1);
      }
      groups.push({
        ...group,
        blocked: false,
        schedule,
      });
    }
  }
  return groups;
};
