import clsx from 'clsx';
import { ToastContent } from 'library/app-types';
import CloseIcon from 'library/icons/CloseIcon';
import MadEmoji from 'library/icons/MadEmoji';
import SmileEmoji from 'library/icons/SmileEmoji';
import React from 'react';

type Props = ToastContent & {
  /** Cerrar el toast */
  onClose: () => void;
};

const classes = {
  toast: `px-6 py-4 bg-white dark:bg-darkGray-dark w-96 border border-gray-200 dark:border-darkGray rounded-lg shadow-2xl relative overflow-hidden`,
  closeBtn: `absolute  right-2 top-2 transition-transform text-gray-200 dark:text-darkGray-light hover:scale-105 hover:text-gray-900 dark:hover:text-gray-50 focus:outline-none`,
  body: `flex items-center relative`,
  bodyTitle: `text-gray-900 dark:text-gray-50 tracking-wide font-medium`,
  bodyDescription: `text-xs text-gray-600 dark:text-whiteLg`,
  iconContainer: `flex flex-col justify-center border-r border-gray-200 dark:border-darkGray-light mr-4 `,
  icon: `flex-shrink-0 w-8 h-10 mr-2`,
  setType(type: 'good' | 'bad') {
    return type === 'good'
      ? 'text-green-500 dark:text-green-200'
      : 'text-red-500 dark:text-red-200';
  },
};
export default function Toast(props: Props) {
  const { title, description, onClose, type } = props;

  return (
    <div className={clsx([classes.toast, type === 'good' ? 'g-toast' : 'c-toast'])}>
      <button onClick={onClose} className={classes.closeBtn}>
        <CloseIcon width={20} height={20} />
      </button>
      <div className={classes.body}>
        <div className={classes.iconContainer}>
          <div className={clsx([classes.icon, classes.setType(type)])}>
            {type === 'good' ? <SmileEmoji /> : <MadEmoji />}
          </div>
        </div>

        <div className="flex-grow">
          <section className="flex flex-col">
            <h3 className={classes.bodyTitle}>{title}</h3>
            <p className={classes.bodyDescription}>{description}</p>
          </section>
        </div>
      </div>
    </div>
  );
}
