import clsx from 'clsx';
import Avatar from 'components/Avatar';
import Switch from 'components/Switch';
import { useAuth } from 'hooks/useAuth';
import useDarkToggle from 'hooks/useDarkMode';
import React, { useCallback } from 'react';

function AppbarMobileDropdown() {
  const auth = useAuth();
  const [theme, setMode] = useDarkToggle();

  const handleChangeMode = useCallback(() => {
    if (theme === 'dark') {
      setMode('dark');
    } else if (theme === 'light') {
      setMode('light');
    }
  }, [setMode, theme]);

  return (
    <div className={clsx(['pt-4 pb-3 border-t'])}>
      <div className="flex items-center px-5">
        <div className="flex-shrink-0">
          {auth && (
            <Avatar
              name={auth.sessionUser.name}
              bgColor={auth.sessionUser.bg}
              font={auth.sessionUser.font}
            />
          )}
        </div>
        <div className="ml-3">
          <div className="text-base font-medium  text-gray-800 dark:text-gray-50">
            {auth?.sessionUser.name}
          </div>
          <div className="text-sm font-medium leading-none text-gray-500 dark:text-whiteLg">
            Estudiante
          </div>
        </div>
      </div>
      <div className="mt-3 px-2 space-y-1">
        <div className="flex items-center justify-between">
          <a
            href="#id5"
            className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-whiteLg hover:text-white hover:bg-gray-700"
          >
            Modo oscuro
          </a>
          <div>
            <Switch onToggle={handleChangeMode} />
          </div>
        </div>

        <button
          onClick={() => auth?.signOut()}
          className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 dark:text-whiteLg hover:text-white hover:bg-gray-700"
          disabled
        >
          Cerrar sesión
        </button>
      </div>
    </div>
  );
}
export default React.memo(AppbarMobileDropdown);
