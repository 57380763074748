import React from 'react';
const classes = {
  alert: `block rounded-lg border my-4 bg-amber-200 border-amber-200`,
  alertBody: `flex flex-col sm:flex-row px-6 py-4 text-white text-gray-900 items-start`,
};

type Props = {
  /** Título de la alerta */
  title: string;
  /** Mensaje de la alerta */
  message: string;
};
function Alert(props: Props): React.ReactElement<Props> {
  const { title, message } = props;
  return (
    <div className={classes.alert}>
      <div className={classes.alertBody}>
        <div className="w-5 h-5 pb-1 text-amber-900 mr-2 flex-shrink-0 pt-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <section className="flex flex-col mt-2 sm:mt-0">
          <h1 className="font-semibold">{title}</h1>
          <p className="text-sm">{message}</p>
        </section>
      </div>
    </div>
  );
}

export default Alert;
