import { useAppDispatch } from 'hooks/useAppDispatch';
import { Group } from 'library/app-types';
import React, { useCallback } from 'react';
import { saveGroupStatus, updateGroupStatuses } from 'redux/slices/subjectsSlice';
import SubjectProfessorGroupItem from './SubjectProfessorGroupItem';

type Props = {
  /** Grupos en los cuales tienen clases */
  groups: Group[];
};
function SubjectProfessorGroups(props: Props) {
  const dispatch = useAppDispatch();

  const onBlockGroup = useCallback(
    (isBlocked: boolean, groupNrc: string) => {
      dispatch(
        saveGroupStatus({
          groupsNrc: [groupNrc],
          blocked: isBlocked,
        }),
      );
      dispatch(updateGroupStatuses());
    },
    [dispatch],
  );

  return (
    <ul className="dropdown mt-2 border border-gray-200 dark:border-darkGray-light dark:bg-darkGray rounded-lg last-no-border mb-2">
      {props.groups.map((group) => {
        return (
          <SubjectProfessorGroupItem key={group.id} group={group} onBlockGroup={onBlockGroup} />
        );
      })}
    </ul>
  );
}

export default SubjectProfessorGroups;
