import React, { Fragment } from 'react';

import { Switch, Route } from 'react-router-dom';

import useRouter from 'hooks/useRouter';

import ScheduleGenerate from './schedule-generate/ScheduleGenerate';
import ScheduleFavs from './schedule-favs/ScheduleFavs';
import ScheduleFilters from './schedule-filters/ScheduleFilters';
import ScheduleSettings from './schedule-settings/ScheduleSettings';
import LayoutRoute from 'components/LayoutRoute';

function ScheduleContent(): React.ReactElement {
  const { match } = useRouter();
  return (
    <Fragment>
      <Switch>
        <LayoutRoute exact path={`${match.url}`} component={ScheduleGenerate} />
        <Route path={`${match.url}/favorites`} component={ScheduleFavs} />
        <Route path={`${match.url}/filters`} component={ScheduleFilters} />
        <Route path={`${match.url}/settings`} component={ScheduleSettings} />
      </Switch>
    </Fragment>
  );
}

export default ScheduleContent;
