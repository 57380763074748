import React from 'react';

import clsx from 'clsx';

import SearchIcon from 'library/icons/SearchIcon';

import CommandButton from 'components/CommandButton';

type Props = {
  /** Toggle de la barra de busqueda */
  toggle: () => void;
};

const classes = {
  baseBtn: `group leading-6 ml-1 font-normal flex items-center space-x-3 sm:space-x-4 hover:text-gray-600 transition-colors duration-200`,
  searchIco: `text-gray-400 dark:text-gray-300 w-5 group-hover:text-gray-600`,
  text: `text-gray-400 transition-colors  group-hover:text-gray-600`,
};

function SubjectSearchButton(props: Props) {
  return (
    <button onClick={props.toggle} className={clsx([classes.baseBtn])}>
      <div className={classes.searchIco}>
        <SearchIcon />
      </div>
      <span className={classes.text}>
        <span className="hidden md:inline-block">Escribe la materia buscar</span>{' '}
        <span className="inline-block md:hidden">Buscar materias</span>
      </span>
      <CommandButton />
    </button>
  );
}
export default React.memo(SubjectSearchButton);
