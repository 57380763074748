import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import PreferencesProvider from 'hooks/useAppPreferences';
import { useModal } from 'hooks/useModal';
import { useAppDispatch } from 'hooks/useAppDispatch';

import { setSchedules, setMatrixTemplate } from 'redux/slices/schedulesSlice';

import AppBar from 'layout/appbar/Appbar';
import ApplicationLayout from 'layout/AppLayout';
import SubBar from 'layout/subbar/SubBar';
import Banner from 'components/Banner';

import { initializeWorker, runWorker, terminateWorker } from 'service/worker';

import ScheduleContentRoutes from './ScheduleContent';
import SchedulesSearch from './SubjectSearch';
import Toast from 'components/Toast';
import { useToast } from '@chakra-ui/toast';

import { ToastContent } from 'library/app-types';
import { NO_SCHEDULE_GENERATED } from 'library/constants';

const Schedules: React.FC = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { toggle, modalOpen } = useModal({ initialMode: false });
  const [generatedSchedulesCount, setGeneratedSchedulesCount] = useState(0);
  const { groupStatuses, currentSubjects } = useSelector((state: RootState) => state.subjects);
  const { allowFullGroups, matrixTemplate } = useSelector((state: RootState) => state.schedules);
  // const [banner, setBanner] = useState(true);

  const displayToastAlert = useCallback(
    (content: ToastContent) => {
      toast({
        position: 'top-right',
        render: (props) => {
          return <Toast onClose={props.onClose} {...content} />;
        },
      });
    },
    [toast],
  );

  /**
   * Si ya no hay materias pero existe al menos un horario generado, resetea los schedules
   *  && currentSchedule.length > 0
   */
  useEffect(() => {
    if (currentSubjects.length === 0) {
      dispatch(setSchedules([]));
      setGeneratedSchedulesCount(0);
    }
  }, [dispatch, currentSubjects]);
  /**
   * Efecto que ejecuta el worker para generar las combinaciones bajo estas 4 circunstancias
   * 1. Si el usuario agrega/elimina una materia, el worker debe dispararse para calcular los horarios
   * 2. Si el usuario agrega/elimina un filtro, el worker debe dispararse para calcular los horarios
   * 3. Si el usuario deja de seleccionar en la tabla, el worker debe dispararse para calcular los horarios
   * 4. Si el usuario cambia el estado de allowFullGroups a verdadero o falso, el worker debe dispararse para calcular los horarios
   * El efecto condicionalmente verifica que al menos exista una materia para empezar a calcular los posibles horarios
   */
  useEffect(() => {
    async function run() {
      if (currentSubjects.length > 0) {
        try {
          const { generatedSchedules } = await runWorker({
            currentSubjects,
            allowFullGroups,
            matrixTemplate,
          });
          if (generatedSchedules.length > 0) {
            dispatch(setSchedules(generatedSchedules));
          } else {
            displayToastAlert({
              type: 'bad',
              title: NO_SCHEDULE_GENERATED.title,
              description: NO_SCHEDULE_GENERATED.description,
            });
          }
          setGeneratedSchedulesCount(generatedSchedules.length);
        } catch (error) {}
      }
    }
    run();
  }, [
    dispatch,
    currentSubjects,
    groupStatuses,
    allowFullGroups,
    matrixTemplate,
    displayToastAlert,
  ]);

  /**
   * Si los horarios generados son mayores a 0, entonces coloca el matrix template al horario generado.
   */
  useEffect(() => {
    if (generatedSchedulesCount > 0) {
      dispatch(setMatrixTemplate(matrixTemplate));
    }
  }, [generatedSchedulesCount, dispatch, matrixTemplate]);

  /**
   * En el caso de que no generes ningun horario posible, entonces coloca los últimos horarios generados.
   * Eso va disparar un re-render que volverá a correr el worker, sin embargo,
   */
  useEffect(() => {
    if (generatedSchedulesCount === 0) {
      dispatch(setSchedules([]));
    }
  }, [generatedSchedulesCount, dispatch]);

  useEffect(() => {
    initializeWorker();
    return () => {
      terminateWorker();
    };
  }, []);

  /**
   * Este efecto me permite controlar los eventos de teclado que permiten abrir el modal
   * de añadir una nueva materia
   */
  useEffect(() => {
    const handleKeyboard = (_e: any) => {
      if (_e.key === 'k' && (_e.ctrlKey || _e.metaKey)) {
        toggle();
      }
    };
    window.addEventListener('keydown', handleKeyboard, false);
    return () => {
      window.removeEventListener('keydown', handleKeyboard, false);
    };
  }, [toggle]);

  const AppBarComponent = useMemo(() => {
    return <AppBar toggle={toggle} />;
  }, [toggle]);

  const SubBarComponent = useMemo(() => {
    return <SubBar generatedSchedulesCount={generatedSchedulesCount} />;
  }, [generatedSchedulesCount]);

  return (
    <Fragment>
      {modalOpen && <SchedulesSearch isOpen={modalOpen} toggle={toggle} />}
      {true && (
        <Banner
          title="Información importante"
          message="Por petición del Dpto. TI de Uninorte Cronun no estará disponible este semestre 😪"
          // onClose={() => setBanner(false)}
        />
      )}
      <ApplicationLayout
        appBar={AppBarComponent}
        subBar={SubBarComponent}
        body={
          <PreferencesProvider>
            <ScheduleContentRoutes />
          </PreferencesProvider>
        }
      />
    </Fragment>
  );
};

export default Schedules;
