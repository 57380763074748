import { useCallback } from 'react';

import { Subject, SubjectHit } from 'library/app-types';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { addSubject } from 'redux/slices/subjectsSlice';

import SubjectSearchItem from './SubjectSearchItem';
import { mixpanel } from 'service/mixpanel';

type Props = {
  /** Lista de materias encontradas dado el search term  */
  subjectHits?: SubjectHit[] | null;
  /** Lista de las materias agregadas actualmente  */
  currentSubjects?: Subject[];
};
function SubjectSearchList(props: Props): React.ReactElement<Props> {
  const { subjectHits, currentSubjects } = props;
  const dispatch = useAppDispatch();

  /**
   * Funcion para verificar si la materia se ha agregado
   * @param subjectAddedId
   */
  const checkIfWasAdded = useCallback(
    (subjectAddedId: string) => {
      if (currentSubjects && currentSubjects.length > 0) {
        const finded = currentSubjects?.filter((elem) => elem.id === subjectAddedId);
        return finded && finded.length > 0;
      } else {
        return false;
      }
    },
    [currentSubjects],
  );

  /** Añade una nueva materia
   *  La función verifica si la materia fue añadida previamente,
   *  para posteriormente añadirla.
   */
  const addNewSubjectHandler = useCallback(
    (subject: Subject) => {
      const wasAdded = checkIfWasAdded(subject.id);
      if (!wasAdded) {
        const { id, name, department, mat, groups } = subject;
        dispatch(
          addSubject({
            id,
            name,
            department,
            mat,
            groups,
          }),
        );

        mixpanel.track('Add New Subject', {
          subjectId: id,
          subjectName: name,
          subjectMat: mat,
        });
      }
    },
    [checkIfWasAdded, dispatch],
  );

  return (
    <ul className="list-none">
      {subjectHits?.map((subjectHit, index) => {
        return (
          <SubjectSearchItem
            key={subjectHit.objectID}
            subjectHit={subjectHit}
            currentIndex={index}
            onAddNewSubject={addNewSubjectHandler}
          />
        );
      })}
    </ul>
  );
}

export default SubjectSearchList;
