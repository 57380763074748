import { useAuth } from 'hooks/useAuth';
import React, { useState } from 'react';
import clsx from 'clsx';
import useRouter from 'hooks/useRouter';
import Avatar from 'components/Avatar';

const dropdownClasses = {
  container: `ml-3 relative z-50`,
  btn: `max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`,
  menu: ` origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-darkGray ring-1 ring-gray-900 dark:ring-gray-400 ring-opacity-5`,
  menuItem: `block w-full text-left px-4 py-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-darkGray-light`,
};

export default function AppbarDropdown() {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const { history } = useRouter();
  const auth = useAuth();

  const handleSignOut = () => {
    auth?.signOut();
    history.replace('/');
  };

  return (
    <div className={dropdownClasses.container}>
      <div>
        <button
          onClick={() => setToggleDropdown((prevState) => !prevState)}
          className={dropdownClasses.btn}
          id="user-menu"
          aria-haspopup="true"
        >
          <span className="sr-only">Open user menu</span>
          {auth && (
            <Avatar
              name={auth.sessionUser.name}
              bgColor={auth.sessionUser.bg}
              font={auth.sessionUser.font}
            />
          )}
        </button>
      </div>

      <div
        className={clsx([dropdownClasses.menu, !toggleDropdown ? `hidden` : null])}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu "
      >
        <span className="block font-medium px-4 py-2 text-sm dark:text-white" role="menuitem">
          {auth?.sessionUser.name}
        </span>

        <button onClick={handleSignOut} className={dropdownClasses.menuItem} role="menuitem" disabled>
          Cerrar sesión
        </button>
      </div>
    </div>
  );
}
