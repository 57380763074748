import List from 'components/list/List';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Subject } from 'library/app-types';
import React, { useCallback } from 'react';
import { selectCurrentSubject } from 'redux/slices/subjectsSlice';
import SubjectNavigationListItem from './SubjectNavigationListItem';

type Props = {
  /** Lista de materias agregadas */
  currentUserSubjects: Subject[];
};

function SubjectNavigationList(props: Props) {
  const { currentUserSubjects } = props;
  const dispatch = useAppDispatch();

  /**
   * Seleccionar una materia para  mostrar los profesores que le corresponden.
   */
  const onSelectSubject = useCallback(
    (subject: Subject) => {
      dispatch(selectCurrentSubject(subject));
    },
    [dispatch],
  );

  return (
    <List>
      {currentUserSubjects.map((subject) => {
        return (
          <SubjectNavigationListItem
            key={subject.id}
            {...subject}
            onSelectSubject={onSelectSubject}
          />
        );
      })}
    </List>
  );
}

export default SubjectNavigationList;
