import SectionDivider from 'components/SectionDivider';
import { parseProfessorGroups } from 'library/helpers';
import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import NoSubjectToFilter from './NoSubjectToFilter';
import SubjectProfessor from './subject-professor/SubjectProfessor';

function ScheduleFiltersContent() {
  const currentSelectedSubject = useSelector(
    (state: RootState) => state.subjects.currentSelectedSubject,
  );

  /** Parsea los grupos tal que pueda obtener los profesores con sus respectivos grupos sin repetir */
  const parsedGroups = useMemo(() => {
    return parseProfessorGroups(currentSelectedSubject?.groups || []);
  }, [currentSelectedSubject]);

  return (
    <div className="px-4 py-4 h-full">
      {currentSelectedSubject && currentSelectedSubject.groups.length > 0 ? (
        <Fragment>
          <SectionDivider title="Bloquear profesores o grupos" />
          <em className="text-xs text-gray-700 dark:text-whiteLg">
            Tip: Haz click sobre el profesor para ver sus grupos y bloquear alguno en específico.
            Algunos profesores no son malos... ¡solo tienen horarios desalmados!
          </em>
          <div className="pt-2">
            {/* Schedule item acordion */}
            {parsedGroups.map(({ groups, professor }, index) => {
              return <SubjectProfessor key={index} groups={groups} professors={professor} />;
            })}
          </div>
        </Fragment>
      ) : (
        <NoSubjectToFilter />
      )}
    </div>
  );
}

export default React.memo(ScheduleFiltersContent);
