import React from 'react';

type Props = {
  /** Numero de presonas con el mismo horario */
  total: number;
};
function TotalSameSchedulesLabel(props: Props) {
  let content;

  if (props.total === 0) {
    content = (
      <>
        ¡Vaya, Todavía no hay personas con un horario marcado igual al tuyo! Eso es bueno, o
        quizá... ¿malo?
      </>
    );
  } else if (props.total === 1) {
    content = (
      <>
        <em>Aviso:</em> ¡{props.total} persona tiene exactamente este horario! Piensan igual, qué
        bien, pero... ¿quién matricula primero? ¡Corre!
      </>
    );
  } else {
    content = (
      <>
        <em>Aviso:</em> {props.total} personas tienen exactamente este horario! Piensan igual, qué
        bien, pero... ¿quién matricula primero? ¡Corre!
      </>
    );
  }

  return (
    <div className="flex justify-end pt-2 ">
      <p className="text-gray-700 text-sm dark:text-whiteLg-high">{content}</p>
    </div>
  );
}

export default TotalSameSchedulesLabel;
