/**
 * Parse day to matrix column index
 * @param {string} day
 * @return {number}
 */
export const parseDay = (day: string) => {
  switch (day) {
    case 'M':
      return 0;
    case 'T':
      return 1;
    case 'W':
      return 2;
    case 'R':
      return 3;
    case 'F':
      return 4;
    case 'S':
      return 5;
    default:
      return 6;
  }
};

/**
 * Parse hour to matrix column index
 * @param {string} day
 * @return {number}
 */
export const parseHour = (hour: number) => {
  switch (hour) {
    case 6:
      return 0;
    case 7:
      return 1;
    case 8:
      return 2;
    case 9:
      return 3;
    case 10:
      return 4;
    case 11:
      return 5;
    case 12:
      return 6;
    case 13:
      return 7;
    case 14:
      return 8;
    case 15:
      return 9;
    case 16:
      return 10;
    case 17:
      return 11;
    case 18:
      return 12;
    case 19:
      return 13;
    case 20:
      return 14;
    default:
      return 15;
  }
};

/**
 * Intepreta una lista de abrebiaciones de días de la semana para devolver
 * su nombre completo
 * @param dayInput String que contiene los días de la semana que correspondan
 */
export const dayInterpreter = (dayInput: string) => {
  let interpretedDay = '';
  const days = dayInput.split('');
  for (let day of days) {
    if (interpretedDay.length) {
      interpretedDay += ', ';
    }
    switch (day) {
      case 'M':
        interpretedDay += 'Lunes';
        break;
      case 'T':
        interpretedDay += 'Martes';
        break;
      case 'W':
        interpretedDay += 'Miércoles';
        break;
      case 'R':
        interpretedDay += 'Jueves';
        break;
      case 'F':
        interpretedDay += 'Viernes';
        break;
      case 'S':
        interpretedDay += 'Sábado';
        break;
      case 'U':
        interpretedDay += 'Domingo';
        break;
      default:
        interpretedDay += 'Domingo';
        return;
    }
  }

  return interpretedDay;
};
