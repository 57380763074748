import { Schedule } from 'library/app-types';
import { dayInterpreter } from 'library/dayHelpers';
import React from 'react';

type Props = {
  /** NRC del grupo del profesor */
  groupNrc: string;
  /** Horarios para este grupo */
  schedules: Schedule[];
};
function SubjectProfessorGroupSchedule(props: Props) {
  const { groupNrc, schedules } = props;

  return (
    <div className="flex flex-col">
      <h4 className="text-sm text-gray-900 dark:text-gray-50 font-semibold leading-6 tracking-wider">
        Código <em>NRC:</em> {groupNrc}
      </h4>
      {/* Hours list */}
      <div className="flex flex-col md:flex-row">
        {schedules.map(({ time, day }, index) => {
          return (
            <span key={index} className="text-gray-700 dark:text-whiteLg text-sm  md:mr-1 pr-2">
              <strong className="font-medium">{dayInterpreter(day)}:</strong>{' '}
              {`${time.start} - ${time.end}`}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default SubjectProfessorGroupSchedule;
