import React from 'react';

type Props = {
  /** Ancho X del vector en px */
  width?: number;
  /** Largo y del vector en px */
  height?: number;
};
const CloseIcon = React.memo(function (props: Props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width + 'px' : '24px'}
      height={height ? height + 'px' : '24px'}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
    </svg>
  );
});

export default CloseIcon;
