import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Group } from 'library/app-types';
import { getScheduleMatrix } from 'library/getScheduleMatrix';
import html2canvas from 'html2canvas';
import { saveAs } from 'library/helpers';
import mixpanel from 'mixpanel-browser';
import { scheduleHours } from 'library/constants';

const dropdownClasses = {
  menu: `origin-top-right z-50 absolute right-0 mt-2 w-48 rounded-md shadow-xl py-1 bg-white dark:bg-darkGray ring-1 ring-gray-200 dark:ring-darkGray-light dark:border-darkGray-dark`,
  menuItem: `block w-full text-left px-4 py-2 text-sm dark:text-gray-50 text-gray-700 hover:bg-gray-100 dark:hover:bg-darkGray-light `,
  btn: `bg-primary relative flex items-center 
  hover:bg-primary-dark  text-sm leading-6  text-white 
  rounded-lg border border-primary-dark px-8 h-9
  focus:outline-none
  focus:ring-2 focus:ring-offset-primary
  `,
};

type Props = {
  /** Schedule actual para tomar el grupo e imprimirlo */
  currentSchedule: Group[];
  /** Nombre del horario a guardar */
  customName?: string;
};
function DownloadScheduleDropdown(props: Props) {
  const { currentSchedule, customName } = props;
  const [toggleDropdown, setToggleDropdown] = useState(false);

  // Remueve la etiqueta creada
  useEffect(() => {
    return () => {
      document.getElementById('downloadLink')?.remove();
    };
  }, []);

  const getGroupInformation = (): string[][] => {
    let bodyData: string[][] = [];

    currentSchedule.forEach(({ nrc, subject: { name }, professors, scheduleType }) => {
      let tableRow: string[] = [];
      const professorNames = professors.map(({ name }) => name).join(',');
      tableRow.push(nrc, name, professorNames, scheduleType);
      bodyData.push(tableRow);
    });

    return bodyData;
  };

  /**
   * Toma los valores del currentSchedule y genera un PDF correspondiente
   */
  const handleSaveToPDF = () => {
    const doc: jsPDF | any = new jsPDF({
      orientation: 'landscape',
    });
    autoTable(doc, {
      html: '#table-to-print',
      theme: 'grid',
      tableWidth: 'wrap',
      pageBreak: 'avoid',
      styles: {
        // minCellWidth: 35,
        cellWidth: 38,
        halign: 'center',
        overflow: 'linebreak',
        fontSize: 7,
      },
    });
    doc.setFontSize(9);

    const finalY = doc.lastAutoTable.finalY;

    const groupInformationTable = getGroupInformation();

    autoTable(doc, {
      pageBreak: 'always',
      startY: finalY + 2,
      head: [['NRC', 'MATERIA', 'DOCENTE(S)', 'MODALIDAD']],
      body: groupInformationTable,
      styles: {
        overflow: 'linebreak',
      },
    });

    const nameOfScheduleToSave = customName
      ? `${customName}.pdf`
      : `cronun_${new Date().toLocaleDateString()}.pdf`;
    doc.save(nameOfScheduleToSave);
    mixpanel.track('Save PDF');
  };

  /**
   * Toma los valores del currentSchedule y genera un CSV correspondiente
   */
  const handleSaveToCsv = () => {
    const matrix: any = getScheduleMatrix(currentSchedule);
    let csvMetadata =
      'data:text/csv;charset=utf-8,Hora,Lunes,Martes,Miercoles,Jueves,Viernes,Sabado\n';
    for (var i = 0; i < 14; i++) {
      csvMetadata = csvMetadata + scheduleHours[i];
      for (var j = 0; j < 7; j++) {
        if (matrix[j][i] === null) {
          csvMetadata += ', ';
        } else {
          csvMetadata += +',' + matrix[j][i];
        }
      }
      csvMetadata += +'\n';
    }
    const encodedUri = encodeURI(csvMetadata);
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', encodedUri);
    downloadLink.setAttribute('id', 'downloadLink');
    const nameOfScheduleToSave = customName
      ? `${customName}.csv`
      : `cronun_${new Date().toLocaleDateString()}.csv`;
    downloadLink.setAttribute('download', nameOfScheduleToSave);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    mixpanel.track('Save CSV');
  };

  /**
   * Toma los valores del currentSchedule y genera un PNG correspondiente
   */
  const handleSaveToPng = () => {
    const tableElement = document.getElementById('table-to-print');
    const nameOfScheduleToSave = customName
      ? `${customName}.png`
      : `cronun_${new Date().toLocaleDateString()}.png`;
    window.scrollTo(0, 0);
    if (tableElement) {
      html2canvas(tableElement, {
        onclone: function (document) {
          const classesToRemove = ['ui-selected', 'bg-secondary', 'hover:bg-secondary'];
          document.querySelectorAll('.ui-selected').forEach((val) => {
            val.classList.remove(...classesToRemove);
          });
        },
      }).then((canvas) => {
        saveAs(canvas.toDataURL(), nameOfScheduleToSave);
      });
    }
    mixpanel.track('Save PNG');
  };

  return (
    <div className="ml-3 relative">
      <button
        className={dropdownClasses.btn}
        onClick={() => setToggleDropdown((prevState) => !prevState)}
      >
        <div className="absolute left-2 w-4 h-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
          </svg>
        </div>
        <span className="sr-only">Descargar horario</span>
        <span>Descargar</span>
        <div className="absolute right-2 w-4 h-4 text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </button>
      <div
        className={clsx([dropdownClasses.menu, !toggleDropdown ? `hidden` : null])}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="download-menu"
      >
        <button onClick={handleSaveToCsv} className={dropdownClasses.menuItem} role="menuitem">
          Descargar como CSV
        </button>
        <button onClick={handleSaveToPng} className={dropdownClasses.menuItem} role="menuitem">
          Descargar como PNG
        </button>
        <button onClick={handleSaveToPDF} className={dropdownClasses.menuItem} role="menuitem">
          Descargar como PDF
        </button>
        {/* <button className={dropdownClasses.menuItem} role="menuitem">
          Compartir
        </button> */}
      </div>
    </div>
  );
}

export default React.memo(DownloadScheduleDropdown);
