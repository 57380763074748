import React from 'react';
import NoData from 'assets/svgs/no_data.svg';

function NoSubjectToFilter() {
  return (
    <div className="w-11/12 h-96 flex items-center justify-center">
      <img className="w-32 h-32" src={NoData} alt="No subject added" />
    </div>
  );
}

export default NoSubjectToFilter;
