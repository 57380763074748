import React from 'react';

type Props = {
  currentPage: number;
  totalPages: number;
  moveToPage: (page: number) => void;
};

function SchedulePaginator(props: Props): React.ReactElement<Props> {
  const { currentPage, moveToPage, totalPages } = props;

  return (
    <div className="w-full md:w-40  shadow-tag rounded-lg">
      <div className="flex h-full items-center">
        <button
          className="flex-shrink-0 dark:bg-darkGray rounded-lg px-2 py-1 flex-grow-0 border-r dark:border-darkGray-light h-full  text-gray-800 dark:text-gray-50 focus:outline-none dark:hover:bg-darkGray-light hover:bg-gray-100 active:bg-gray-200 dark:active:bg-darkGray-dark"
          onClick={() => moveToPage(currentPage - 1)}
        >
          <span className="sr-only">Anterior horario</span>
          <div className="w-4 h-4 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </div>
        </button>
        <span className="flex-1 justify-center text-xs text-center dark:text-gray-50">
          {currentPage + 1}/{totalPages}
        </span>
        <button
          className="flex-shrink-0 dark:bg-darkGray rounded-lg px-2 py-1 flex-grow-0 border-l dark:border-darkGray-light h-full  text-gray-800 dark:text-gray-50 focus:outline-none dark:hover:bg-darkGray-light hover:bg-gray-100 active:bg-gray-200 dark:active:bg-darkGray-dark"
          onClick={() => moveToPage(currentPage + 1)}
        >
          <span className="sr-only">Siguiente horario</span>
          <div className="w-4 h-4 text-gray-600 dark:text-gray-50">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
}

export default React.memo(SchedulePaginator);
