import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/remote-config';

const firebaseConfig = {
  apiKey: 'AIzaSyATN_aHpPiYo9fE0pnBfD6fB8Wb58MlhqU',
  authDomain: 'cronunapp.firebaseapp.com',
  projectId: 'cronunapp',
  storageBucket: 'cronunapp.appspot.com',
  messagingSenderId: '322783542845',
  appId: '1:322783542845:web:987688cd4902bebf2af454',
  measurementId: 'G-HB68LGVV22',
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
