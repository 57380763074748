import clsx from 'clsx';
import React from 'react';
import { createSelectable, TSelectableItemProps } from 'react-selectable-fast';

type Props = TSelectableItemProps & {
  /** Posición X,Y de una matriz que tiene o un elemento o nulo  */
  matrixPosValue: string | null;
  /** Posición X,Y de la celda  */
  posIndex: string;
};

const selectableItemClasses = {
  baseTd: `selectable border border-gray-200 dark:border-darkGray-light 
  px-3 w-32 h-8 cursor-pointer  whitespace-nowrap text-center text-gray-800 
  dark:text-whiteLg-high overflow-hidden overflow-ellipsis break-all text-xs`,
  notSelected: `bg-white dark:bg-darkGray hover:bg-gray-50 dark:hover:bg-darkGray-light`,
  // isSelected: `ui-selected bg-secondary dark:bg-secondary hover:bg-secondary`,
  isSelected: `ui-selected`,
  // isSelecting: `bg-secondary-light dark:bg-secondary-light ui-selecting`,
  isSelecting: `ui-selecting`,
};

const TableSelectableItem = (props: Props): React.ReactElement<Props> => {
  const { matrixPosValue, selectableRef, isSelected, isSelecting, posIndex } = props;

  return (
    <td
      id={posIndex}
      ref={selectableRef}
      className={clsx([
        selectableItemClasses.baseTd,
        isSelected ? selectableItemClasses.isSelected : selectableItemClasses.notSelected,
        isSelecting ? selectableItemClasses.isSelecting : '',
      ])}
    >
      {matrixPosValue === null || matrixPosValue === 'blocked' ? '' : matrixPosValue}
    </td>
  );
};

export default React.memo(createSelectable(TableSelectableItem));
