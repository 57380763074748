import React from 'react';

type Props = {
  /** Componente a renderizar del lado izquierdo */
  leftSide: React.ReactNode;
  /** Componente a renderizar del lado derecho */
  rightRide: React.ReactNode;
};

function TwoSideLayout(props: Props): React.ReactElement<Props> {
  return (
    <div className="block mt-2 max-w-screen-xl mx-auto py-2 sm:px-6  lg:px-7">
      <div className="flex flex-col md:flex-row w-full">
        <aside className="w-full md:w-3/12  h-full">
          {/* <h1 className="text-xl font-medium">Materias agregadas</h1> */}
          {props.leftSide}
        </aside>
        <div className="w-full md:w-9/12  h-auto">{props.rightRide}</div>
      </div>
    </div>
  );
}

export default TwoSideLayout;
