import ClassicSideNavigation from 'pages/schedule/schedule-generate/classic-side-navigation/ClassicSideNavigation';
import React from 'react';
import TwoSideLayout from './TwoSideLayout';
type Props = {
  children: React.ReactNode;
};
function ClassicLayout(props: Props) {
  return (
    <main>
      <TwoSideLayout leftSide={<ClassicSideNavigation />} rightRide={props.children} />
    </main>
  );
}

export default React.memo(ClassicLayout);
