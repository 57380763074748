import clsx from 'clsx';
import React from 'react';

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  /** Variantes del boton */
  variant: 'primary' | 'secondary' | 'ghost' | 'error' | 'success' | 'tag';
  /** Tamaños del boton */
  size: 'tiny' | 'large' | 'normal';
};

const classes = {
  buttonBase: `
  w-full 
  relative
  text-center
  transition-colors rounded-lg  
  font-medium focus:outline-none 
  focus:ring-2
  inline-flex
  items-center
  justify-center
  disabled:opacity-50
  `,
  primary: `bg-primary 
  text-white hover:bg-primary-dark dark:bg-primary-light 
  dark:text-black dark:text-gray-900 focus:ring-blue-300 dark:hover:text-gray-900`,
  secondary: `bg-secondary text-white hover:bg-secondary-dark dark:bg-secondary-light dark:text-black focus:ring-secondary-light`,
  error: `bg-red-500 text-white hover:bg-red-600 dark:bg-red-300 dark:text-gray-900 focus:ring-red-300 dark:hover:bg-red-400`,
  success: `bg-green-500 text-white hover:bg-green-600 dark:bg-green-300 dark:text-black focus:ring-green-300`,
  tag: `bg-white text-gray-800 px-5 py-2 shadow-tag active:shadow-tag-pressed hover:bg-gray-50 active:bg-gray-100`,
  ghost: `bg-transparent hover:bg-gray-100 text-primary dark:hover:bg-darkGray-light`,
  withVariant(variant: 'primary' | 'secondary' | 'ghost' | 'error' | 'success' | 'tag') {
    switch (variant) {
      case 'primary':
        return this.primary;
      case 'secondary':
        return this.secondary;
      case 'ghost':
        return this.ghost;
      case 'error':
        return this.error;
      case 'success':
        return this.success;
      case 'tag':
        return this.tag;
      default:
        return this.primary;
    }
  },
};

export default function Button(props: ButtonProps): React.ReactElement {
  const { variant, size } = props;

  const sizes = {
    tiny: `py-2 px-4 text-xs`,
    large: `py-4 px-10 text-sm`,
    normal: 'px-4 py-2 text-sm',
    withSize(size: 'tiny' | 'large' | 'normal') {
      switch (size) {
        case 'tiny':
          return this.tiny;
        case 'large':
          return this.large;
        default:
          return this.normal;
      }
    },
  };

  return (
    <button
      className={clsx([classes.buttonBase, classes.withVariant(variant), sizes.withSize(size)])}
      {...props}
    >
      {props.children}
    </button>
  );
}
