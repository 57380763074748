import React, { useCallback } from 'react';

import { Subject } from 'library/app-types';

import { useAppDispatch } from 'hooks/useAppDispatch';

import { removeSubject } from 'redux/slices/subjectsSlice';

import UserSubjectListItem from './UserSubjectListItem';
import { removeAllSchedules, setMatrixTemplate } from 'redux/slices/schedulesSlice';
import { generateEmptyMatrix } from 'library/generateEmptyMatrix';

type Props = {
  currentSubjects: Subject[];
};

const EMPTY_MATRIX = generateEmptyMatrix();

function UserSubjectList(props: Props) {
  const { currentSubjects } = props;
  const dispatch = useAppDispatch();

  const onRemoveSubject = useCallback(
    (id: string) => {
      dispatch(removeSubject({ id }));
      if (currentSubjects.length === 1) {
        // document
        //   .querySelectorAll('.ui-selected')
        //   .forEach((elem) => elem.classList.remove('ui-selected'));
        dispatch(setMatrixTemplate(EMPTY_MATRIX));
        dispatch(removeAllSchedules());
      }
    },
    [dispatch, currentSubjects.length],
  );

  return (
    <div className="px-8 pt-2 pb-8">
      <ul>
        {currentSubjects.map((subject) => {
          return (
            <UserSubjectListItem
              key={subject.id}
              onRemoveSubject={onRemoveSubject}
              subject={subject}
            />
          );
        })}
      </ul>
    </div>
  );
}

export default React.memo(UserSubjectList);
