import React, { useState } from 'react';
import ScheduleFavsList from './ScheduleFavsList';

function ScheduleFavsSidenav() {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className="rounded-lg w-full px-4 md:px-0">
      <div className="py-4 ">
        <h1 className="text-xl text-gray-900 font-medium leading-4 dark:text-gray-50 ">
          Horarios favoritos
        </h1>
        <input
          tabIndex={0}
          className=" block mt-2 w-full rounded-md border-gray-300 dark:bg-darkGray dark:text-gray-50 dark:border-darkGray-light shadow-sm focus:border-primary focus:ring focus:ring-primary-light text-sm focus:ring-opacity-50"
          type="text"
          autoComplete="false"
          value={searchQuery}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
          placeholder="Nombre del horario"
        />
      </div>
      <ScheduleFavsList searchQuery={searchQuery} />
    </div>
  );
}

export default ScheduleFavsSidenav;
