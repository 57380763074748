import React, { useEffect, useRef, useState } from 'react';

import { SubjectHit } from 'library/app-types';
import CloseIcon from 'library/icons/CloseIcon';
import SearchIcon from 'library/icons/SearchIcon';

import CommandButton from 'components/CommandButton';

import { subjectsIndex } from 'service/algolia';
import { mixpanel } from 'service/mixpanel';

type Props = {
  /** Funcion toggle para cerrar el modal */
  onClose: () => void;
  /** State dispatcher del query de búsqueda */
  onLoadHits: (subjectHits: SubjectHit[]) => void;
  /** Ultimos resultados obtenidos de la búsqueda  */
  onLoadLastResult: (searchTerm: string) => void;
};

function SubjectSearchBar(props: Props): React.ReactElement<Props> {
  const { onClose, onLoadHits, onLoadLastResult } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const timer = setTimeout(async () => {
      if (
        searchTerm === inputRef.current?.value &&
        (searchTerm.length === 0 || searchTerm.length >= 3)
      ) {
        const results = await subjectsIndex.search<SubjectHit>(searchTerm);
        const hits = results.hits;
        onLoadHits(hits);
        onLoadLastResult(searchTerm);
        mixpanel.track('Search', {
          searchTerm,
        });
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchTerm, onLoadHits, inputRef, onLoadLastResult]);

  return (
    <div className="flex group items-center justify-between">
      <div className=" text-gray-500 cursor-pointer mr-4 group-hover:text-gray-600 dark:text-whiteLg dark:group-hover:text-gray-50">
        <SearchIcon />
      </div>
      <div className="flex-grow">
        <input
          ref={inputRef}
          id="searchInput"
          name="searchInput"
          placeholder="Prueba buscando por MAT o por el nombre de la materia"
          className="w-full focus:outline-none placeholder-gray-400 dark:placeholder-whiteLg-light px-0 font-regular dark:bg-darkGray dark:text-gray-50 border-none ring-0 focus:ring-0"
          type="text"
          autoComplete="off"
          aria-autocomplete="none"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="inline-flex">
        <CommandButton singleCommand="Esc" />
        <button
          type="button"
          onClick={onClose}
          className="text-gray-400 ml-2 dark:text-whiteLg z-50 cursor-pointer"
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}

export default React.memo(SubjectSearchBar);
