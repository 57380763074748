import React from 'react';

import clsx from 'clsx';
import { useQuery } from '@apollo/client';

import getSubjectsGroupsQuery from 'graphql/queries/getSubjectsGroups';

import { Subject, SubjectHit, SubjectsMutationData, SubjectsMutationVars } from 'library/app-types';
import { parseGroups } from 'library/parseGroups';
import DocumentAdd from 'library/icons/DocumentAddIcon';
import { useAppDispatch } from 'hooks/useAppDispatch';

import { setLoadingAddingSubject } from '../../redux/slices/appSlice';

type Props = {
  /** Registro de materia a mostrar*/
  subjectHit: SubjectHit;

  currentIndex: number;
  /** Determina si el elemento es clickeable */
  onAddNewSubject: (subject: Subject) => void;
};

export const searchItemClasses = {
  wrapper: `relative mt-1 group cursor-pointer`,
  container: `block rounded-lg bg-gray-50 dark:bg-darkGray-light group-hover:bg-primary dark:group-hover:bg-primary-light pt-0 pr-5 pl-4 pb-0 `,
  row: `flex items-center h-16 active:bg-primary-dark dark:active:bg-primary-300 `,
  col: `flex flex-col flex-1`,
  icon: `w-5 h-5 text-gray-600 dark:text-gray-50 mr-5 group-hover:text-gray-100 dark:group-hover:text-gray-900 `,
  subjectMat: `text-gray-500 dark:text-whiteLg text-xs group-hover:text-primary-light dark:group-hover:text-gray-800`,
  subjectName: `capitalize overflow-ellipsis group-hover:text-white dark:text-whiteLg-high dark:group-hover:text-gray-900 font-medium text-sm md:text-base leading-6`,
};

export default function SubjectSearchItem(props: Props): React.ReactElement<Props> {
  const { subjectHit, onAddNewSubject } = props;
  const dispatch = useAppDispatch();
  const { name, mat } = subjectHit;
  const getSubjectGroupsQuery = useQuery<SubjectsMutationData, SubjectsMutationVars>(
    getSubjectsGroupsQuery,
    {
      skip: true,
    },
  );

  const addNewSubjectHandler = () => {
    dispatch(setLoadingAddingSubject(true));
    try {
      getSubjectGroups();
    } catch (error) {
      dispatch(setLoadingAddingSubject(false));
    }
  };

  const getSubjectGroups = async () => {
    const fetchedSubjectGroupsResponse = await getSubjectGroupsQuery.refetch({
      subjectId: subjectHit.objectID,
    });

    if (fetchedSubjectGroupsResponse) {
      const { data } = fetchedSubjectGroupsResponse;
      const { name, department, mat, objectID: id } = subjectHit;
      onAddNewSubject({
        id,
        name,
        department,
        mat,
        groups: parseGroups(data.getSubjectGroups),
      });
    }
    dispatch(setLoadingAddingSubject(false));
  };

  return (
    <li className={clsx([searchItemClasses.wrapper])}>
      <div className={clsx([searchItemClasses.container])}>
        <div onClick={addNewSubjectHandler} className={searchItemClasses.row}>
          <div className={searchItemClasses.icon}>
            <DocumentAdd />
          </div>
          <div className={searchItemClasses.col}>
            <span className={searchItemClasses.subjectMat}>{mat}</span>
            <span className={searchItemClasses.subjectName}>{name}</span>
          </div>
        </div>
      </div>
    </li>
  );
}
