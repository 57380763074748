import { useCallback, useState, useEffect } from 'react';

const useDarkMode = (): any => {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || '');

  useEffect(() => {
    // Si el tema esta
    if (!theme) {
      setTheme('media');
    }
  }, [theme]);

  const setMode = useCallback((mode: string) => {
    if (mode !== 'media') {
      localStorage.setItem('theme', mode);
      if (mode === 'dark') {
        document.querySelector('html')?.classList.add('dark');
      } else {
        document.querySelector('html')?.classList.remove('dark');
      }
    }
    setTheme(mode);
  }, []);

  return [theme, setMode];
};

export default useDarkMode;
