/**
 * Genera una matriz vacía de 14x7
 */
export const generateEmptyMatrix = () => {
  const m = new Array(7);
  for (let j = 0; j < m.length; j++) {
    m[j] = new Array(14).fill(null);
  }
  return m;
};

// export const generateMatrixFrom = (data: any[][]) => {
//   const m = new Array(7);
//   for (let j = 0; j < m.length; j++) {
//     m[j] = new Array(14).fill(data[j]);
//   }
// }
