import { createContext, useCallback, useState, useContext } from 'react';

import { UserPreferences } from 'library/app-types';

import { setAllowFullGroups } from 'redux/slices/schedulesSlice';

import { useAppDispatch } from './useAppDispatch';

type PreferencesContextType = {
  setNewPreferences: (userPreferences: UserPreferences) => void;
  userPreferences: UserPreferences;
};

export const PreferenceContext = createContext<PreferencesContextType>({
  setNewPreferences: (userPreferences: UserPreferences) => {},
  userPreferences: {
    allowFullGroups: true,
    viewMode: 0,
  },
});

type PreferenceProviderProps = {
  children: React.ReactNode;
};
function PreferencesProvider(props: PreferenceProviderProps) {
  // Leemos la configuración de localstorage primero para ver que datos tiene el usuario
  const localPreferences: UserPreferences = JSON.parse(
    localStorage.getItem('userPreferences') + '',
  );
  const [userPreferences, setUserPreferences] = useState<UserPreferences>(
    localPreferences || {
      allowFullGroups: true,
      viewMode: 0,
    },
  );

  const setNewPreferences = (newPreferences: UserPreferences) => {
    setUserPreferences(newPreferences);
    localStorage.setItem('userPreferences', JSON.stringify(newPreferences));
  };

  return (
    <PreferenceContext.Provider value={{ userPreferences, setNewPreferences }}>
      {props.children}
    </PreferenceContext.Provider>
  );
}

export default PreferencesProvider;

export const useAppPreferences = (): [
  UserPreferences,
  (newPreferences: UserPreferences) => void,
] => {
  const { userPreferences, setNewPreferences } = useContext<PreferencesContextType>(
    PreferenceContext,
  );
  const dispatch = useAppDispatch();

  const setUserPreferences = useCallback(
    (newPreferences: UserPreferences) => {
      setNewPreferences(newPreferences);
      dispatch(setAllowFullGroups(newPreferences.allowFullGroups));
    },
    [setNewPreferences, dispatch],
  );

  return [userPreferences, setUserPreferences];
};
