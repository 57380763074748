import { configureStore } from '@reduxjs/toolkit';
import app from './slices/appSlice';
import schedules from './slices/schedulesSlice';
import subjects from './slices/subjectsSlice';
const store = configureStore({
  reducer: {
    app,
    schedules,
    subjects,
  },
  // middleware: [],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
