import { useAppDispatch } from 'hooks/useAppDispatch';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentSubject } from 'redux/slices/subjectsSlice';
import { RootState } from 'redux/store';
import SubjectNavigationList from './SubjectNavigationList';

function ScheduleFiltersSidenav() {
  const currentUserSubjects = useSelector((state: RootState) => state.subjects.currentSubjects);
  const currentSelectedSubject = useSelector(
    (state: RootState) => state.subjects.currentSelectedSubject,
  );
  const dispatch = useAppDispatch();

  // Selecciona la primera materia y la despliega del lado derecho siempre que se renderize el componente
  useEffect(() => {
    if (currentUserSubjects.length > 0 && !currentSelectedSubject) {
      dispatch(selectCurrentSubject(currentUserSubjects[0]));
    }
  }, [currentUserSubjects, dispatch, currentSelectedSubject]);

  return (
    <div className="rounded-lg w-full px-4 md:px-0 ">
      <div className="py-4">
        <h1 className="text-xl text-gray-900 dark:text-gray-50 font-medium leading-4 ">
          Materias agregadas
        </h1>
        <span className="text-gray-500 dark:text-whiteLg text-sm">
          {currentUserSubjects.length} Materias agregadas
        </span>
      </div>
      {currentUserSubjects.length > 0 ? (
        <SubjectNavigationList currentUserSubjects={currentUserSubjects} />
      ) : (
        <div className="w-full">
          <p className="text-sm inline-flex w-6/6 leading-1 dark:text-whiteLg-light">
            Parece que aún no has agregado ninguna materia.
          </p>
        </div>
      )}
    </div>
  );
}
export default React.memo(ScheduleFiltersSidenav);
