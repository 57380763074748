import SectionDivider from 'components/SectionDivider';
import { useAppPreferences } from 'hooks/useAppPreferences';
import React, { Fragment } from 'react';
import SettingBox from './SettingBox';
import SettingsAppereance from './settings-appereance/SettingsAppereance';

type Props = {
  /** Tab necesario para saber que contenido mostrar */
  tabIndex: number;
};

function ScheduleSettingsContent(props: Props): React.ReactElement<Props> {
  const { tabIndex } = props;
  const [preferences, setUserPreferences] = useAppPreferences();

  /**
   * Cambia las preferencias del usuario entre permitir o no horarios
   * sin cupo.
   */
  const onTogglePreferences = () => {
    setUserPreferences({
      ...preferences,
      allowFullGroups: !preferences.allowFullGroups,
    });
  };
  /**
   * Cambia el modo de visualización de la aplicación al modo clásico.
   */
  const onToggleClassicView = () => {
    setUserPreferences({
      ...preferences,
      viewMode: preferences.viewMode === 0 ? 1 : 0,
    });
  };

  return (
    <div className="px-8 py-4 h-full">
      {tabIndex === 0 ? (
        <Fragment>
          <SectionDivider title="Ajustes generales" />
          <SettingBox
            isToggled={preferences.allowFullGroups}
            onSettingToggled={onTogglePreferences}
            settingName="Generar horarios sin cupo"
            description="Activa esta opción si quieres generar horarios aun si estos no tienen cupos para matricular. Esto puede ser útil si prefieres
      especular por encima de asegurar un horario."
          />
          <SettingBox
            isToggled={preferences.viewMode === 1}
            onSettingToggled={onToggleClassicView}
            settingName="Activar vista clásica"
            description="Activar esta opcion permite visualizar las materias a un lado de la tabla de horarios. Ideal para quienes prefieren tener una vista clara de los profesores que va matricular por encima del horario que tengan."
          />
        </Fragment>
      ) : (
        <SettingsAppereance />
      )}
    </div>
  );
}
export default ScheduleSettingsContent;
