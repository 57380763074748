import React from 'react';
type Props = {
  /** Lista de profesores  */
  professors: string;
  /** ViewMode  */
  viewMode?: number;
};
function ProfessorsNames(props: Props) {
  const { professors, viewMode } = props;
  return (
    <span className={`${viewMode === 0 ? ` text-sm` : `text-xs`} dark:text-whiteLg text-gray-800`}>
      {professors}
    </span>
  );
}

export default ProfessorsNames;
