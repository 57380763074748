import React from 'react';

function Checkbox(
  props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
) {
  return (
    <div className="flex items-center h-5">
      <input
        type="checkbox"
        className={`focus:ring-primary dark:focus:ring-primary-300  h-4 w-4 text-primary border-gray-300 dark:border-darkGray-light   dark:bg-darkGray rounded mr-3`}
        {...props}
      />
    </div>
  );
}

export default Checkbox;
