import { useAppDispatch } from 'hooks/useAppDispatch';
import TwoSideLayout from 'layout/TwoSideLayout';
import React, { useEffect } from 'react';
import { selectCurrentSubject } from 'redux/slices/subjectsSlice';
import ScheduleFiltersContent from './ScheduleFiltersContent';
import ScheduleFiltersSidenav from './sidenav/ScheduleFiltersSidenav';

export default function ScheduleFilters() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    // Limpia los grupos seleccionados al salir de los filtros
    return () => {
      dispatch(selectCurrentSubject(null));
    };
  }, [dispatch]);
  return (
    <TwoSideLayout leftSide={<ScheduleFiltersSidenav />} rightRide={<ScheduleFiltersContent />} />
  );
}
