import { gql } from '@apollo/client';

/**
 * Obtiene los horarios del estudiante
 */
export default gql`
  {
    userSchedules {
      id
      name
      groups {
        id
        nrc
        total
        scheduleType
        groupNumber
        professors {
          id
          name
        }
        subject {
          id
          name
          mat
          department {
            name
            code
          }
        }
        schedule {
          time {
            start
            end
          }
          place
          day
          startDate
          endDate
        }
        quota {
          taken
          free
        }
        bg
        font
      }
      total
      createdAt
    }
  }
`;
