import React from 'react';

type Props = {
  generatedSchedules: number;
};
function SubBarDetails(props: Props) {
  const { generatedSchedules } = props;
  return (
    <div>
      <span className="text-xs md:text-sm leading-none md:leading-normal text-gray-500 dark:text-gray-300">
        Total generados:{' '}
        <strong className="text-gray-600 dark:text-gray-200 font-medium inline-flex">
          {generatedSchedules}
        </strong>
      </span>
    </div>
  );
}
export default React.memo(SubBarDetails);
