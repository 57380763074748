import { setUser } from '@sentry/react';
import moment from 'moment';
import { createContext, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { mixpanel } from 'service/mixpanel';
import { AUTH_TOKEN_COOKIE, USER_LOCAL_STORAGE } from '../library/constants';

const defaultCookiesParams = {
  path: '/',
  expires: moment(new Date()).add(1, 'day').toDate(),
  sameSite: true,
};

type SessionUser = {
  /** User ID (student code) */
  id: string;
  /** Background color del avatar */
  bg: string;
  /** Font color de las iniciales en el icono del avatar */
  font: string;
  /** Nombre del usuario en sesión */
  name: string;
};

interface AuthContextInterface {
  setTokenCookie: (token: string) => void;
  setSessionUserData: (data: any) => void;
  sessionUser: SessionUser;
  signOut: () => void;
}

export const AuthContext = createContext<AuthContextInterface | null>(null);

type Props = {
  children: React.ReactNode;
};

function AuthProvider({ children }: Props) {
  const [, setCookie, removeCookie] = useCookies([AUTH_TOKEN_COOKIE]);
  // const userLocalStorage = localStorage.getItem(USER_LOCAL_STORAGE);
  // Dummy data - only for playground
  const userLocalStorage = JSON.stringify({
    name: 'Dorian',
    bg: 'rgba(59, 130, 246, 0.5)',
    font: 'white'
  });

  const [sessionUser, setSessionUser] = useState(
    userLocalStorage ? JSON.parse(userLocalStorage) : null,
  );

  if (userLocalStorage) {
    mixpanel.identify(JSON.parse(userLocalStorage).id);
    setUser({ id: JSON.parse(userLocalStorage).id });
  }

  /**
   * Método que desloguea a un usuario de su sesión actual
   */
  const signOut = async () => {
    removeCookie(AUTH_TOKEN_COOKIE, { path: '/' });
    localStorage.removeItem(USER_LOCAL_STORAGE);
    setSessionUser(null);
    mixpanel.track('Logged Out');
    mixpanel.reset();
    setUser(null);
  };

  /**
   * Guarda el token usando las cookies del navegador.
   * @param token Token string a guardar
   */
  const setTokenCookie = (token: string) => {
    setCookie(AUTH_TOKEN_COOKIE, token, defaultCookiesParams);
  };

  /**
   * Guarda un usuario en localStorage.
   * @param data Objeto a guardar en el localStorage
   */
  const setSessionUserData = (data: any) => {
    localStorage.setItem(USER_LOCAL_STORAGE, JSON.stringify(data));
    setSessionUser(data);
  };

  return (
    <AuthContext.Provider value={{ sessionUser, setSessionUserData, signOut, setTokenCookie }}>
      {children}
    </AuthContext.Provider>
  );
}

/**
 * Hook para obtener el objeto auth.
 */
const useAuth = (): AuthContextInterface | null => {
  const auth = useContext<AuthContextInterface | null>(AuthContext);
  return auth;
};

export { useAuth, AuthProvider };
