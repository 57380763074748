import React from 'react';

type Props = {
  /** Coindicencias en los grupos */
  total: number;
};
function TotalGroupCoincidence(props: Props) {
  let content;

  if (props.total === 1) {
    content = (
      <>
        <em>Aviso:</em> ¡{props.total} persona tiene exactamente este grupo!
      </>
    );
  } else {
    content = (
      <>
        <em>Aviso:</em> {props.total} personas tienen exactamente este grupo!
      </>
    );
  }

  return (
    <div className="flex pt-2">
      <p className="text-gray-700 text-xs dark:text-whiteLg-high">{content}</p>
    </div>
  );
}
export default React.memo(TotalGroupCoincidence);
