import React from 'react';

const classes = {
  list: `list-none last-no-border border border-gray-300 dark:border-darkGray-light rounded-lg navigation__list`,
  // itemBase: `border-b px-4 py-4 text-sm transition-colors cursor-pointer `,
  // selectedItem: `bg-primary text-white border-primary-dark`,
  // notSelected: `bg-white hover:bg-gray-100 border-gray-200`,
};

type Props = {
  /** Lista de elementos a desplegar */
  children: React.ReactNode;
};
function List(props: Props): React.ReactElement<Props> {
  return <ul className={classes.list}>{props.children}</ul>;
}

export default List;
