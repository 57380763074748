import React, { Fragment } from 'react';

type Props = {
  /** Titulo de la sección */
  title: string;
};
function SectionDivider(props: Props) {
  return (
    <Fragment>
      <h3 className="text-lg text-gray-900 dark:text-gray-50 leading-10">{props.title}</h3>
      <div className="d-block h-1px bg-gray-200 dark:bg-darkGray-light"></div>
    </Fragment>
  );
}

export default SectionDivider;
