import NoSubjectToFilter from 'pages/schedule/schedule-filters/NoSubjectToFilter';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import CurrentSubjectsList from '../current-groups-modal/CurrentGroupList';
import ActionsCopyButton from './ActionsCopyButton';

function ClassicSideNavigation(): React.ReactElement {
  const currentSubjectGroups = useSelector((state: RootState) => state.schedules.currentSchedule);

  return (
    <div className="md:mr-1 px-4 md:px-0 mt-2">
      <div className="flex justify-between relative mr-6">
        <h1 className="text-xl font-medium text-gray-900 dark:text-whiteLg-high">
          Lista de materias
        </h1>
        <ActionsCopyButton currentSubjects={currentSubjectGroups} />
      </div>
      <div className="max-h-580 overflow-y-auto pb-6 md:mr-2 mt-2">
        {currentSubjectGroups.length > 0 ? (
          <CurrentSubjectsList readOnly={false} currentSchedule={currentSubjectGroups} />
        ) : (
          <div>
            <span className="text-xs text-gray-400">Agrega materias primero en el buscador</span>
            <NoSubjectToFilter />
          </div>
        )}
      </div>
    </div>
  );
}

export default ClassicSideNavigation;
