import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuth } from 'hooks/useAuth';
import { AUTH_TOKEN_COOKIE } from 'library/constants';
import { useCookies } from 'react-cookie';
import { API_URL } from '../enviroment';
// import Login from 'pages/login/Login';
import { Switch, Route, Redirect } from 'react-router-dom';
// import PrivateRoute from './PrivateRoute';
import Schedules from 'pages/schedule/Schedule';

const httpLink = createHttpLink({
  uri: API_URL,
  // credentials: 'cross-origin',
});

function App() {
  const [cookies] = useCookies([AUTH_TOKEN_COOKIE]);
  const auth = useAuth();

  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: cookies[AUTH_TOKEN_COOKIE],
    },
  }));

  // Creamos un error link para poder interceptar errores
  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (extensions?.code === 'UNAUTHENTICATED') {
          if (cookies[AUTH_TOKEN_COOKIE]) {
            auth?.signOut();
          }
        } else {
          console.error(
            `GraphQL error: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        }
      });
    }
  });

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  if (
    localStorage.getItem('theme') === 'dark' ||
    (!localStorage.getItem('theme') && window.matchMedia('(prefers-color-scheme: dark)').matches)
  ) {
    document.querySelector('html')!.classList.add('dark');
  } else {
    document.querySelector('html')!.classList.remove('dark');
  }

  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route path="/schedules" component={Schedules} />
        <Redirect to="/schedules" />
        {/* <PrivateRoute path="/schedules" component={NoApp} /> */}
      </Switch>
    </ApolloProvider>
  );
}

export default App;
