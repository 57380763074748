import FocusTrap from 'focus-trap-react';
import { useCallback, useEffect } from 'react';
import Portal from './Portal';
type ModalProps = {
  /** Mostrar u ocultar modal */
  isOpen: boolean;
  /** Funcion mostrar u ocultar el modal */
  onClose: () => void;
  /** Contenido del modal */
  children: React.ReactNode;
};
export default function Modal(props: ModalProps): React.ReactElement<ModalProps> | null {
  const { isOpen, onClose } = props;

  const onCloseModal = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onClose();
    },
    [onClose],
  );

  const handleKeyboardClose = useCallback(
    (e: any) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        window.removeEventListener('keydown', (e: any) => handleKeyboardClose(e));
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardClose, false);
    return () => {
      window.removeEventListener('keydown', handleKeyboardClose);
    };
  }, [handleKeyboardClose]);

  const modalClasses = {
    wrapper: `justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`,
    container: `justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`,
    overlay: `fixed inset-0`,
    backdrop: `absolute inset-0 bg-gray-500 dark:bg-gray-900 opacity-30`,
  };

  return isOpen ? (
    <Portal className="modal-portal">
      <FocusTrap>
        <div role="dialog" aria-modal="true" className={modalClasses.wrapper}>
          {/* Overlay */}
          <div
            onClick={(e: React.MouseEvent<HTMLDivElement>) => onCloseModal(e)}
            className={modalClasses.overlay}
            aria-hidden="true"
          >
            <div className={modalClasses.backdrop}></div>
          </div>
          {props.children}
        </div>
      </FocusTrap>
    </Portal>
  ) : null;
}
