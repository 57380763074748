import CheckIcon from 'library/icons/CheckIcon';

type Props = {
  /** Query de búsqueda el cual no fue encontrado */
  searchTerm: string;
};

export default function SubjectSearchNotFound(props: Props) {
  const { searchTerm } = props;
  return (
    <div className="h-48">
      <p className="pb-8 pt-5 text-gray-500 dark:text-whiteLg">
        No hay resultados para "
        <strong className="text-gray-900 dark:text-gray-50">{searchTerm}</strong>"
      </p>
      <div className="flex flex-col">
        <p className="border-b border-gray-200 dark:border-darkGray-light uppercase tracking-wid text-sm text-gray-700 dark:text-gray-50">
          Prueba lo siguiente:
        </p>
        <ol>
          <li className="text-gray-800 dark:text-gray-100 py-4 inline-flex items-center">
            <div className="mr-4 w-5 h-5">
              <CheckIcon />
            </div>
            Verifica el nombre de la materia, quizá no sea el que estás escribiendo
          </li>
          <li className="text-gray-800 dark:text-gray-100 inline-flex items-center">
            <div className="mr-4 w-5 h-5">
              <CheckIcon />
            </div>
            Si el nombre es el correto y la materia no aparece puedes{' '}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://chat.whatsapp.com/KC7pOGeCUUb4kT7Di75NId"
              className="text-blue-400 dark:text-blue-300 font-medium pl-1 cursor-pointer"
            >
              {' '}
              repotarlo acá
            </a>
          </li>
        </ol>
      </div>
    </div>
  );
}
