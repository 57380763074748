import { ScheduleWorkerResponse, Subject } from 'library/app-types';

let scheduleWorker: Worker | undefined = undefined;

interface WorkerParams {
  currentSubjects: Subject[];
  matrixTemplate: any[];
  allowFullGroups: boolean;
}

export function runWorker({
  currentSubjects,
  matrixTemplate,
  allowFullGroups,
}: WorkerParams): Promise<ScheduleWorkerResponse> {
  return new Promise((resolve, reject) => {
    if (scheduleWorker) {
      scheduleWorker.postMessage({ currentSubjects, matrixTemplate, allowFullGroups });
      scheduleWorker.onerror = (errEvt) => reject(errEvt);
      scheduleWorker.onmessage = (e) => {
        const response = e.data as ScheduleWorkerResponse;
        resolve(response);
      };
    }
  });
}

export function initializeWorker() {
  scheduleWorker = new Worker('/schedule.worker.js');
}

export function terminateWorker() {
  if (scheduleWorker) {
    scheduleWorker.terminate();
    scheduleWorker = undefined;
  }
}
