import { useMutation } from '@apollo/client';
import { useToast } from '@chakra-ui/toast';
import { captureException } from '@sentry/react';
import Modal from 'components/Modal';
import Toast from 'components/Toast';
import saveSchedule from 'graphql/mutations/saveSchedule';
import getUserSchedules from 'graphql/queries/getUserSchedules';
import { useAuth } from 'hooks/useAuth';
import {
  ModalProps,
  SaveScheduleMutationData,
  SaveScheduleMutationVariables,
  ToastContent,
} from 'library/app-types';
import CloseIcon from 'library/icons/CloseIcon';
import SpinIcon from 'library/icons/SpinIcon';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { mixpanel } from 'service/mixpanel';

const modalClasses = {
  panelContainer: `relative w-full my-6 mx-auto max-w-xs `,
  panelContent: `border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-white 
  dark:bg-darkGray outline-none focus:outline-none `,
};

function SaveScheduleModal(props: ModalProps) {
  const { toggle, isOpen } = props;
  const [name, setName] = useState('');
  const currentSchedule = useSelector((state: RootState) => state.schedules.currentSchedule);
  const toast = useToast();
  const inputRef = useRef<any>();
  const auth = useAuth();
  const [commitMutation, { loading }] = useMutation<
    SaveScheduleMutationData,
    SaveScheduleMutationVariables
  >(saveSchedule);

  const displayToastAlert = (content: ToastContent) => {
    toast({
      render: (props) => {
        return <Toast onClose={props.onClose} {...content} />;
      },
    });
  };

  const getCurrentScheduleGroups = () => {
    const groups = currentSchedule
      .filter(({ blocked }) => !blocked)
      .map(({ id, nrc }) => ({ id, nrc }));
    return groups;
  };

  const saveUserScheduleMutation = async () => {
    setName('');
    const groups = getCurrentScheduleGroups();
    const response = await commitMutation({
      variables: {
        name,
        groups,
      },
      refetchQueries: [
        {
          query: getUserSchedules,
        },
      ],
    });

    if (response.data) {
      mixpanel.track('Save Schedule', {
        savedScheduleName: name,
        savedScheduleGroups: groups,
      });
      displayToastAlert({
        type: 'good',
        title: '¡Hecho!',
        description: 'Horario marcado correctamente',
      });
    }
  };

  const handlePinSchedule = () => {
    if (currentSchedule.length > 0) {
      try {
        saveUserScheduleMutation();
      } catch (error) {
        captureException(error, {
          user: {
            id: auth?.sessionUser.id,
          },
        });
        displayToastAlert({
          type: 'bad',
          title: 'Ocurrió un problema!',
          description: error.message.substring(15),
        });
      }
      toggle();
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <Modal onClose={toggle} isOpen={isOpen}>
      {/* Panel */}
      <div className={modalClasses.panelContainer}>
        <div className={modalClasses.panelContent}>
          <section className="px-9 pt-11 pb-4">
            <h1 className="text-xl sm:text-4xl text-gray-900 dark:text-whiteLg-high font-bold">
              Guardar horario
            </h1>
          </section>
          <button
            tabIndex={0}
            onClick={toggle}
            className="absolute right-6 top-6 w-5 h-5 rounded-lg text-gray-400"
          >
            <CloseIcon />
          </button>
          <section className="pb-11 px-9 flex flex-col">
            <p className="text-sm pb-4 text-gray-700 dark:text-whiteLg">
              Guarda tu horario y obten más estadísticas sobre el
            </p>
            <input
              ref={inputRef}
              tabIndex={0}
              className=" block w-full rounded-md border-gray-300 dark:bg-darkGray dark:text-gray-50 dark:border-darkGray-light shadow-sm focus:border-primary focus:ring focus:ring-primary-light text-sm focus:ring-opacity-50"
              type="text"
              autoComplete="false"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              placeholder="Nombre del horario a marcar"
            />
          </section>
          <hr className="divide divide-x-1 divide-gray-200 w-full"></hr>

          {/* TODO: Desacoplar en componentes */}
          <button
            onClick={handlePinSchedule}
            disabled={loading || currentSchedule.length === 0}
            className="px-8 py-5 text-sm font-semibold flex justify-center text-white text-center bg-primary dark:bg-primary-light dark:text-gray-900 w-full rounded-b-3xl focus:outline-none focus:ring-1 focus:ring-offset-blue-300 hover:opacity-95 active:bg-primary-dark dark:active:text-white"
          >
            {currentSchedule.length > 0 ? (
              loading ? (
                <SpinIcon />
              ) : (
                'Guardar horario'
              )
            ) : (
              'No hay horario para marcar'
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default SaveScheduleModal;
