import { parseDay } from './dayHelpers';
import { generateEmptyMatrix } from './generateEmptyMatrix';
/**
 * Get schedule matrix
 * @param {Group} group
 * @return {string[][]} Matrix filled by schedules groups
 */
export const getScheduleMatrix = (groups: any[]) => {
  let days: string[];
  let start, end;
  let matrix = generateEmptyMatrix();
  for (let group of groups) {
    for (let schedule of group.schedule) {
      days = schedule.day.split('');
      while (days.length) {
        start = Number(schedule.time.start.substring(0, 2));
        end = Number(schedule.time.end.substring(0, 2));
        for (let hour = start; hour < end; hour++) {
          if (
            (matrix[parseDay(days[0])][hour - 6] &&
              matrix[parseDay(days[0])][hour - 6] !== group.subject.name) ||
            (matrix &&
              matrix[parseDay(days[0])][hour - 6] &&
              matrix[parseDay(days[0])][hour - 6] !== group.subject.name)
          ) {
            return false;
          }
          matrix[parseDay(days[0])][hour - 6] = group.subject.name;
        }
        days.shift();
      }
    }
  }
  return matrix;
};
