import { useToast } from '@chakra-ui/toast';
import Toast from 'components/Toast';
import { Group, ToastContent } from 'library/app-types';
import CopyIcon from 'library/icons/CopyIcon';
import React, { Fragment, useState, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

const buttonClasses = {
  btn: `shadow-sm border hover:bg-gray-100 flex items-center 
  dark:hover:bg-darkGray-light  border-gray-200 dark:border-darkGray-light 
  bg-white dark:bg-darkGray-light rounded-lg px-2 py-1 text-gray-900 dark:text-whiteLg focus:outline-none`,
  // menu: `origin-top-right
  // z-40
  //  absolute right-0 mt-7 w-48 rounded-md shadow-lg py-1 bg-white dark:bg-darkGray
  //  ring-1 ring-gray-200 dark:ring-darkGray-light  `,
  // menuItem: `block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-darkGray-light dark:text-whiteLg dark:bg-darkGray`,
};

type Props = {
  currentSubjects: Group[];
};
export default function ActionsCopyButton(props: Props) {
  const { currentSubjects } = props;
  const [nrcs, setNrcs] = useState('');
  const toast = useToast();

  /**
   * Cuando inicia el componente, copio los nrcs que actualmente a un estado.
   * Este estado es el que utiliza la libreria copytoclipboard para hacer efectiva la copia del texto.
   */
  useEffect(() => {
    const nrcs = currentSubjects.map(({ nrc }) => nrc).join(',');
    setNrcs(nrcs);
  }, [currentSubjects]);

  const displayToastMessage = (content: ToastContent) => {
    toast({
      render: (props) => {
        return <Toast onClose={props.onClose} {...content} />;
      },
    });
  };

  /**
   * Copia los nrcs del usuario al clipboard
   */
  const handleCopyNrc = () => {
    if (currentSubjects.length > 0) {
      displayToastMessage({
        title: 'Códigos copiados',
        description: `Copiaste los códigos: ${nrcs}`,
        type: 'good',
      });
    }
  };

  return (
    <Fragment>
      <CopyToClipboard onCopy={handleCopyNrc} text={nrcs}>
        <button title="Copiar codigos NRCs" className={buttonClasses.btn}>
          <div className="w-3 h-3 ">
            <CopyIcon />
          </div>
          <span className="text-xs text-gray-800 ml-1">Copiar</span>
        </button>
      </CopyToClipboard>

      {/* <div
        className={clsx([dropdownClasses.menu, !toggleDropdown ? `hidden` : null])}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="download-menu"
      >
        <CopyToClipboard onCopy={handleCopyNrc} text={nrcs}>
          <button className={dropdownClasses.menuItem} role="menuitem">
            Copiar códigos NRC
          </button>
        </CopyToClipboard>
      </div> */}
    </Fragment>
  );
}
