import { Dispatch, SetStateAction, useCallback, useState } from 'react';

type initialState = {
  /** Estado inicial del modal: abierto o cerrado */
  initialMode: boolean;
};

type useModalData = {
  /** Estado de modal abierto o cerrado */
  modalOpen: boolean;
  /** State updater function del componente [opcional] */
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  /** Toggle del modal para abrirlo y cerrarlo */
  toggle: () => void;
};

export const useModal = ({ initialMode = false }: initialState): useModalData => {
  const [modalOpen, setModalOpen] = useState(initialMode);
  const toggle = useCallback(() => setModalOpen(!modalOpen), [modalOpen]);
  return { modalOpen, setModalOpen, toggle };
};
