import React from 'react';

const placeHolderSubjects = new Array(10).fill(null);

export default function SubjectSearchListLoading() {
  return (
    <ul className="w-full h-full list-none">
      {placeHolderSubjects.map((_, i) => {
        return (
          <li key={i} className="relative mt-1 group animate-pulse">
            <div className="block rounded-lg bg-gray-100 dark:bg-darkGray-light pt-0 pr-5 pl-4 pb-0 ">
              <div className="flex items-center h-16 ">
                <div className="w-10 h-10 bg-gray-400 dark:bg-darkGray rounded-full animate-pulse mr-4"></div>
                <div className="flex flex-col flex-1">
                  <span className="h-2 w-20 bg-gray-200 dark:bg-darkGray rounded-lg animate-pulse mb-2"></span>
                  <span className="h-6 w-60 bg-gray-200 dark:bg-darkGray rounded-lg animate-pulse"></span>
                </div>
              </div>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
