import React, { Fragment } from 'react';
import SubBarDetails from './SubBarDetails';
import SubBarDropown from './SubBarDropdown';
import SubBarTabs from './SubBarTabs';

type Props = {
  /** Numero de horarios generados */
  generatedSchedulesCount: number;
};
function SubBar(props: Props) {
  // const { tabIndex, onSelectTab } = props;
  return (
    <Fragment>
      <header className="bg-gray-50 border-b border-gray-100 dark:bg-darkGray dark:border-darkGray-light h-32 relative">
        <div className="max-w-screen-xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <SubBarDropown />

          <div className="flex items-center ">
            <div className="w-5 h-5 text-gray-800 dark:text-gray-300 mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h3 className="text-base md:text-lg font-normal leading-tight text-gray-900 dark:text-gray-50">
              {/* Horarios generado/<strong className="font-medium">unnamed1</strong> */}
              Horarios generados
            </h3>
          </div>
          <SubBarDetails generatedSchedules={props.generatedSchedulesCount} />
          <SubBarTabs />

          {/* <span className="bottom-0 w-40 transition-all transform translate-x-40 border-b-2 border-secondary  absolute rounded-full"></span> */}
        </div>
      </header>
    </Fragment>
  );
}
export default SubBar;
